var QUEO = window.QUEO || {};

QUEO.Swiper = (function($) {

    var $b = $('body'),
        $modules = $b.find('.js-swiper'),
        $target,
        swiper,
        $swiper;


    function _init() {

        // tab focus handling
        $modules.find('a').on('mousedown', function(e) {

            // to prevent calling focus event on right mouse click, this does not interfere with default handling of links
            e.preventDefault();

        }).on('focus', swipeToFocused);

        // left/right navigation handling
        $modules.find('.swiper-button-wrapper').on('mousedown', function(e) {
            // prevents accidental dragging of slide when using left/right navigation
            e.stopPropagation();
        });

        // enhance swiper, check actual function for more comments
        $modules.on('init resize', checkSwiper);

        // add svg element to maintain 16:9 ratio (mobile breakpoint) for iframe elements (embedded via ccm)
        $modules.find('.js-ccm-component').prepend('<svg class="c-video__shaper" height="450" width="800"/>');

        // ensure slide with playing media is paused if not in current slide
        $modules.on('init', function() {

            var $media = $modules.find('audio,video');

            // store slide index on media element
            $media.each(function() {

                $(this).data('slideIndex',  $(this).closest('.js-swiper-slide').prevAll('.js-swiper-slide:not(.swiper-slide-duplicate)').length);

            });

            this.swiper.on('slideChange', function() {

                var swiper = this;

                $media.each(function() {

                    if(swiper.activeIndex !== $(this).data('slideIndex') && !this.paused) {

                        this.pause();

                    }

                });

            });

        });

    }

    function checkSwiper(e, swiper) {

        $swiper = $(swiper.el);

        // sets class to "unstyle" swiper (no pagination nor nav) if less slides are present than would fit into view
        $swiper.toggleClass('no-swiper-styling', $swiper.find('.' + swiper.params.slideClass + ':not(.' + swiper.params.slideDuplicateClass + ')').length < swiper.params.slidesPerView);

        // prevent tabbing into cloned slides
        $swiper.find('.' + swiper.params.slideDuplicateClass + ' a').attr('tabindex', -1);

    }

    function swipeToFocused(e) {

        $target = $(e.target);
        swiper = $target.closest('.js-swiper').get(0).swiper;

        if(swiper) {

            // to prevent weird issue when slider is looped and user is navigating via shift + tab (reverse)
            $target.closest('.js-swiper').scrollLeft(0);

            swiper.slideTo(
                // get slide containing focused link via used slideClass
                // determine slide index via .prevAll().length
                $target.closest('.' + swiper.params.slideClass).prevAll().length
            );

        }

    }

    $(_init);

})(jQuery);