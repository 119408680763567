var QUEO = window.QUEO || {};

QUEO.suggest = (function ($, w, d) {
    var $w = $(w),
        $d = $(d);

    function init() {

        $('.js-suggest-location:not(.js-initialized-loc)').addClass('js-initialized-loc').each(function() {

            var elComponent = $(this).get(0),
                elInput = $(this).find('input[type="text"]').get(0),
                $inputLat = $(this).find('input[data-lat]'),
                $inputLon = $(this).find('input[data-lon]');

            if(navigator.geolocation) {
                $(this).find('.ph-button').on('click', function() {

                    elInput.classList.add('is-processing');
                    elComponent.classList.remove('is-open');

                    navigator.geolocation.getCurrentPosition(function(position) {

                        $(elInput).data('suggest', {
                            lon: position.coords.longitude,
                            lat: position.coords.latitude
                        });

                        elInput.value = "Ihr momentaner Standort";
                        elInput.classList.remove('is-processing');
                        elInput.dispatchEvent(new Event('blur'));

                        $(elInput).trigger('suggestUpdated');

                    }, function() {

                        elInput.value = '';
                        elInput.classList.remove('is-processing');
                        elInput.dispatchEvent(new Event('blur'));

                        $(elInput).trigger('suggestUpdated');

                    });

                });
            } else {

                $(this).removeClass('contains-button').find('.ph-button').remove();

            }

            $(elInput).on('suggestUpdated', function() {

                var suggest = $(elInput).data('suggest');
                console.log(suggest);
                if(suggest && suggest.hasOwnProperty('lat') && suggest.hasOwnProperty('lon')) {
                    $inputLat.val(suggest.lat);
                    $inputLon.val(suggest.lon);
                } else {
                    $inputLat.val('');
                    $inputLon.val('');
                }

            });

        });
        $('.js-queo-suggest:not(.js-initialized)').addClass('js-initialized').each(function(i) {

            var $component = $(this),
                cUid = i + '_' + Date.now(),
                $input = $component.find('input[type="text"]'),
                input = $input.get(0),
                minCharsBeforeQuerying = 3,
                apiPath = $input.data('suggest-api'),
                suggestsMax = $input.data('suggests-max') || 10,
                queryParam = $input.data('query-param') || 'q',
                query = {},
                $suggestsList = $('<div class="ph-formfield__suggest-list" tabindex="-1"/>'),
                suggestsList = $suggestsList.get(0),
                suggestValueMap = $input.data('suggest-value-map'),
                suggestItemMap = $input.data('suggest-item-map'),
                $suggestsContainer = $component,
                suggestsContainer = $suggestsContainer.get(0),
                suggestId,
                ignoreEvent = false,
                ignoreTabLeave = false,
                ignoreKey = false,
                handlerBound = false,
                ajaxReq,
                ajaxTo,
                $toggles,
                emitToReact = input.hasOwnProperty('_wrapperState') ? function() {

                    var reactEvent,
                        nativeSelectValueSetter = Object.getOwnPropertyDescriptor(
                        w.HTMLInputElement.prototype,
                        "value"
                    ).set;
                    nativeSelectValueSetter.call(input, input.value);

                    reactEvent = new Event("reactChange", {bubbles: true});
                    input.dispatchEvent(reactEvent);

                } : false;

            $input.data({
                    suggest: null,
                    suggests: null
                })
                .on('focus', function() {
                    $input.addClass('has-focus');
                })
                .on('input', function(e) {

                    // renderSuggests function triggers 'input' for outside components to access suggests list
                    if(ignoreEvent) {

                        ignoreEvent = false;
                        return;

                    }

                    if(e.target.value.length >= minCharsBeforeQuerying) {

                        query[queryParam] = e.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                        if(apiPath && apiPath !== '') {

                            // abort previous api requests
                            abortApiRequest();

                            $input.addClass('is-processing');

                            // "type ahead": delay request to api
                            ajaxTo = setTimeout(function() {

                                ajaxReq = $.post(apiPath, query, renderSuggests);

                            }, 200);

                            return;

                        }

                        var suggestData = input.dataset.suggestData;

                        if(typeof suggestData === 'string') {

                            suggestData = JSON.parse(suggestData);

                        } else {

                            suggestData = null;

                        }

                        if(suggestData !== null && typeof suggestData === 'object' && suggestData.hasOwnProperty('suggestions')) {

                            var suggests = {
                                    suggestions: {}
                                },
                                count = 0;

                            Object.keys(suggestData.suggestions).some(function(suggest) {

                                if(suggest.match(RegExp(query[queryParam], 'gi'))) {

                                    suggests.suggestions[suggest] = suggestData.suggestions[suggest];
                                    count++;

                                }

                                if(count >= suggestsMax) {

                                    return true;

                                }

                            });

                            renderSuggests(suggests);

                        }

                    } else {

                        // unset suggest data on input
                        $input.data({
                            suggest: null,
                            suggests: null
                        });

                        $input.trigger('suggestUpdated');

                        $input.removeClass('is-processing');
                        $suggestsContainer.removeClass('is-open');
                        abortApiRequest();

                    }

                })
                .on('keydown', function(e) {

                    var key = e.originalEvent.keyCode;

                    // ignore if not up/down or list is actually empty
                    if(!(key === 38 || key === 40) || $suggestsList.is(':empty')) {
                        return;
                    }

                    e.preventDefault();

                    // set flag to prevent handling same key event in function keyHandler (=global onKeydown handler)
                    ignoreKey = true;

                    // regardless if up or down was pressed always the first item in list receives focus
                    $suggestsList.find('input:eq(0)').focus();

                })
                .on('blur', function() {

                    // if someone types really quick and list is not shown yet abort api request
                    if($input.hasClass('is-processing') && $suggestsList.is(':empty')) {

                        $input.removeClass('is-processing');
                        abortApiRequest();

                    }

                    if(!$component.hasClass('is-open')) {

                        $input.removeClass('has-focus');

                        if(emitToReact) {

                            emitToReact();

                        }

                    }

                })
                .after($suggestsList);

            function abortApiRequest() {

                if(ajaxTo) {

                    clearTimeout(ajaxTo);

                }

                if(typeof ajaxReq === 'object') {

                    ajaxReq.abort();

                }

                ajaxTo = undefined;
                ajaxReq = undefined;

            }

            function updateInputValue(e, suggest) {

                e.preventDefault();

                abortApiRequest();

                // update value, store suggest and remove has-focus class
                $input.val(e.target.value).data({
                    suggest: suggest
                }).removeClass('has-focus').trigger('suggested');

                $input.trigger('suggestUpdated');

                $component.removeClass('is-open');

                bindHandler(false);

                // if used within react a custom synthetic event must be emitted
                if(emitToReact) {

                    emitToReact();

                }

            }

            // bind or unbind handlers (also calculate alignment and init vars on bind)
            function bindHandler(bind) {

                handlerBound = bind;

                if(bind) {

                    // bind events when open
                    $d.on('click', outsideHandler).on('keydown', keyHandler);
                    $w.on('scroll resize', alignment);
                    $suggestsList.on('wheel', scrollHandler);

                    // also calculate alignment
                    alignment();

                } else {

                    // unbind events when close
                    $d.off('click', outsideHandler).off('keydown', keyHandler);
                    $w.off('scroll resize', alignment);
                    $suggestsList.off('wheel', scrollHandler);

                }

            }

            // close on outside click/focus
            function outsideHandler(e) {

                if(e.target === suggestsContainer || $(e.target).closest('.js-queo-suggest').get(0) === suggestsContainer) {

                    return;

                }

                $input.removeClass('has-focus');

                if(emitToReact) {

                    emitToReact();

                }

                $component.removeClass('is-open');

                bindHandler(false);

            }

            // keyboard handler (TAB, ENTER, SPACE, ESC, KEY DOWN/UP)
            function keyHandler(e) {

                // ignore key press, note: this flag is set onKeydown on the input
                if(ignoreKey) {

                    ignoreKey = false;
                    return;

                }

                var key = e.originalEvent.keyCode,
                    prevent = true,
                    $currentToggle = $toggles.filter(':focus');

                if($currentToggle.length === 0) {

                    $currentToggle = $toggles.filter(':checked').eq(0);

                }

                // set flag (controls leaving on tab key if last element)
                ignoreTabLeave = true;

                switch(key) {

                    case 9: // tab

                        ignoreTabLeave = e.shiftKey === true;
                        prevent = false;

                        break;

                    case 13: // enter

                        $toggles.filter(':focus').trigger('click');
                        $input.focus();
                        break;

                    case 27: // esc

                        $input.focus();
                        break;

                    case 38: // key up

                        if($currentToggle.parent().prev().length) {
                            $toggles.eq($currentToggle.parent().prevAll().length - 1).focus();
                        } else {
                            $toggles.eq(-1).focus();
                        }

                        break;

                    case 40: // key down

                        if($currentToggle.parent().next().length) {
                            $toggles.eq($currentToggle.parent().prevAll().length + 1).focus();
                        } else {
                            $toggles.eq(0).focus();
                        }

                        break;

                    default:

                        prevent = false;

                }

                if(prevent) {

                    e.preventDefault();

                }

            }

            // prevents outside scrolling while scrolling inside box
            function scrollHandler(e) {

                QUEO.Utils.preventScrolling(e.originalEvent, suggestsList);

            }

            // calculate alignment (above or below)
            function alignment() {

                var vpTop = -$w.scrollTop() + $input.offset().top,
                    listHeight = $suggestsList.outerHeight(true),
                    spaceLeftAbove = vpTop - listHeight,
                    spaceLeftBelow = (w.innerHeight - vpTop) - ($input.outerHeight(true) + listHeight);

                // Note: prefer below even when there is too little space above and below
                $suggestsContainer.toggleClass('is-drop-up', spaceLeftBelow < 0 && spaceLeftAbove > spaceLeftBelow);

            }
            
            function renderSuggests(suggests) {

                // set ignoreEvent flag
                ignoreEvent = true;

                // remove processing class, update data and trigger 'input' for listening outside components
                $input.removeClass('is-processing').data({
                    suggest: null,
                    suggests: suggests
                }).trigger('input');

                $input.trigger('suggestUpdated');

                // if used within react a custom synthetic event must be emitted
                if(emitToReact) {

                    emitToReact();

                }

                if(suggests.hasOwnProperty('suggestions') && typeof suggests.suggestions === 'object') {

                    suggests = Object.keys(suggests.suggestions);

                    $suggestsList.empty().append(suggests.map(function(suggest, index) {

                        suggestId = 'suggest_' + cUid + '_' + index;

                        return $('<div class="ph-formfield__suggest" />').append([
                            $('<input id="' + suggestId + '" type="radio" value="' + suggest + '">')
                                .on('click', function(e) { updateInputValue(e, suggest); }),
                            '<label for="' + suggestId + '">' + suggest + '</label>'
                        ]);

                    }));

                } else {

                    $suggestsList.empty().append(suggests.map(function(suggest, index) {

                        suggestId = 'suggest_' + cUid + '_' + index;

                        return $('<div class="ph-formfield__suggest" />').append([
                            $('<input id="' + suggestId + '" type="radio" value="' + mapValue(suggest, suggestValueMap) + '">')
                                .on('click', function(e) { updateInputValue(e, suggest); }),
                            '<label for="' + suggestId + '">' + mapValue(suggest, suggestItemMap) + '</label>'
                        ]);

                    }));

                }

                $toggles = $suggestsList.find('input');

                $toggles.eq(-1).on('blur', function() {

                    if(!ignoreTabLeave) {

                        $component.removeClass('is-open');

                        bindHandler(false);

                    }

                });

                $component.toggleClass('is-open', suggests.length !== 0);

                // don't rebind if handler was already bound
                if(handlerBound && suggests.length !== 0) {
                    return;
                }

                bindHandler(suggests.length !== 0);
                
            }

            function mapValue(suggest, keyMap) {
                return typeof keyMap === 'string' ? suggest[keyMap] : keyMap.map(function(key) { return suggest[key]; }).join(' ');
            }

        });

    }

    $(init);

    return {
        init: init
    };
})(window.jQuery, window, document);
