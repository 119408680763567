var QUEO = window.QUEO || {};

QUEO.mFormFilter = (function ($) {


    function _init() {

        var animationDuration = 200;

        // bind event for toggle and ensure its opened initially if "is-open" was set via BE
        $('.js-form-filter-toggle-filters-trigger')
            // bind event listener
            .on('click _init', function () {


            var $trigger = $(this),
                $moreFiltersWrapper = $trigger.closest('.js-form-filter-toggle-filters'),
                $moreFiltersContent = $moreFiltersWrapper.find('.js-form-filter-toggle-filters-content');

            if($moreFiltersWrapper.hasClass('is-open')) {

                $moreFiltersContent.velocity('slideUp', {
                    duration: animationDuration,
                    begin: function() {
                        $moreFiltersWrapper.removeClass('is-open');
                        $trigger.attr('aria-expanded', false);
                        $moreFiltersContent.attr('aria-hidden', true);

                    }
                });

            }

            else {

                $moreFiltersContent.velocity('slideDown', {
                    duration: animationDuration,
                    begin: function() {

                        $moreFiltersWrapper.addClass('is-open');
                        $trigger.attr('aria-expanded', true);
                        $moreFiltersContent.attr('aria-hidden', false);

                    }
                });

            }

        })
            //show if open on init
            .filter('[aria-expanded="true"]').closest('.js-form-filter-toggle-filters').find('.js-form-filter-toggle-filters-content').show();

        // bind change handler for any input or select to submit form
        $('.js-form-filter-toggle-filters--submit-on-change').find('input,select').on('change', function() {
            $(this).closest('form').submit();
        });

    }

    $(_init);

})(jQuery);