(function(w, $) {

    var isClickFocus;

    function _init() {

        $('.js-svg-map').each(function() {

            var $module = $(this),
                $linkCollectionContainer = $module.find('.js-link-collection'),
                $linkCollection = $linkCollectionContainer.find('a'),
                $container = $module.find('.js-svg-map-container'),
                $svg = $container.find('svg'),
                $svgNode = $svg.find('#shapes').get(0);

            if( $linkCollection.length > 0 ) {
                for( var i = 0; i < $linkCollection.length; i++) {
                    $svgNode.insertAdjacentHTML("afterend", $linkCollection.get(i).outerHTML);
                }
                //remove node from dom
                $linkCollectionContainer.remove();
            }

            $svg.find('a')
                // Note: disable accessibility features from QUEO.Accessibility on $(this) via setting data-no-a11y
                .data('no-a11y', true)
                // toggle flag to determine if it was focused on click
                .on('pointerdown pointerup pointercancel', toggleFlag)
                // blur element on actual click
                .on('click', blurOnClick)
                // scroll focused element into view (only when TAB is used)
                .on('focus', adjustFocusPosition);

        });

        function toggleFlag(e) {

            isClickFocus = e.type === 'pointerdown';

        }

        function blurOnClick() {

            if(isClickFocus) {

                this.blur();

            }

        }

        function adjustFocusPosition() {

            if(isClickFocus) {
                return;
            }

            var headerHeight = $('.js-header.is-collapsed .js-header-bar, .js-header:not(.is-collapsed)').outerHeight() || 0,
                elementHeight = this.getBoundingClientRect().height,
                elementOffset = $(this).offset().top,
                visibleTop = elementOffset - w.scrollY - headerHeight >= 0,
                visibleBottom = (elementOffset + elementHeight) - (w.scrollY + w.innerHeight) < 0;

            // move element into visible area
            if(!visibleTop || !visibleBottom) {
                w.scroll(w.scrollX,elementOffset - (visibleTop ? w.innerHeight - elementHeight : headerHeight));
            }

        }

    }

    $(_init);

})(window, window.jQuery);