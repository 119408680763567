/*global jQuery:false */
var QUEO = window.QUEO ||{};

QUEO.Utils = {
	applyScrollPrevention: function(el, noSkip) {

		if(!el) {

			return;

		}

		if(noSkip) {
			el.dataset.noSkip = 'true';
		}

		el.addEventListener('wheel', QUEO.Utils.preventScrolling);
		el.addEventListener('touchstart', QUEO.Utils.preventScrolling);
		el.addEventListener('touchmove', QUEO.Utils.preventScrolling);

	},
	removeScrollPrevention: function(el) {

		if(!el) {

			return;

		}

		el.removeEventListener('wheel', QUEO.Utils.preventScrolling);
		el.removeEventListener('touchstart', QUEO.Utils.preventScrolling);
		el.removeEventListener('touchmove', QUEO.Utils.preventScrolling);

	},

	/**
	 * prevents scrolling
	 * @param originalEvent in common js pass e.originalEvent and when react just e
	 * @param scrollContainer the element with the scroll (overflow-y: auto)
	 * @param noSkip (optional) when true prevent default scrolling even if there is no scrollbar (true => modals, false => selects)
	 */
	preventScrolling: function(originalEvent, scrollContainer, noSkip) {

		scrollContainer = scrollContainer || this;

		var boxHeight = Math.round($(scrollContainer).outerHeight()),
			scrollHeight = scrollContainer.scrollHeight + parseInt(QUEO.Utils.computedStyle(scrollContainer, 'border-top-width')) + parseInt(QUEO.Utils.computedStyle(scrollContainer, 'border-bottom-width')),
			scrollTop = scrollContainer.scrollTop,
			deltaY;

		noSkip = noSkip ? noSkip : scrollContainer.dataset.noSkip;

		// nothing to scroll - skip
		if(!noSkip && boxHeight === scrollHeight) {

			return;

		}

		// Note: please DON'T(!) change to "if else" as this breaks things (due to bad implementation in some browsers using some properties but not populating them)
		if('detail' in originalEvent) {

			deltaY = originalEvent.detail * -1;

		}
		if('wheelDelta' in originalEvent) {

			deltaY = originalEvent.wheelDelta;

		}
		if('wheelDeltaY' in originalEvent) {

			deltaY = originalEvent.wheelDeltaY;

		}
		if('deltaY' in originalEvent) {

			deltaY = originalEvent.deltaY * -1;

		}

		if(originalEvent.touches) {

			if(originalEvent.type === 'touchstart') {

				scrollContainer._deltaY = originalEvent.touches[0].clientY;
				return;

			}
			if(originalEvent.type === 'touchmove') {

				deltaY = scrollContainer._deltaY > originalEvent.touches[0].clientY ? -1 : 1;
				scrollContainer._deltaY = originalEvent.touches[0].clientY;

			}

		}

		// prevent over-scrolling (meaning document will be scrolled instead of dropdown item list)
		// Note: if "reached bottom" && "scrolled down" OR "reached top" && "scrolled up"
		if(originalEvent.cancelable !== false && (scrollTop === scrollHeight - boxHeight && deltaY < 0 || scrollTop === 0 && deltaY > 0)) {

			originalEvent.preventDefault();

		}

	},

	// helper for preventScrolling (-> scrollHandler)
	computedStyle: function(el, prop) {

		var styles = window.getComputedStyle(el);

		return styles.getPropertyValue(prop);

	},

	/**
	 * gets absolute offset top for an element
	 * @param el element
	 * @returns {number} top offset
	 */
	getOffsetTop: function(el) {
		var elOffset = el,
			offsetTop = 0;
		while (elOffset) {
			offsetTop += elOffset.offsetTop;
			elOffset = elOffset.offsetParent;
		}
		return offsetTop;
	},

	/**
	 * immediately (or smoothly) scroll to element (with fixed header adjustment if present)
	 * @param el the element to scroll to
	 * @param smooth use smooth transition
	 */
	scrollToElement: function(el, smooth) {
		var header = document.querySelector('.js-header'),
			headerOffset = header ? header.clientHeight : 0,
			x = window.scrollX,
			y = QUEO.Utils.getOffsetTop(el) - headerOffset;

		if(smooth) {
			window.scrollTo({
				left: x,
				top: y,
				behavior: 'smooth'
			})
		} else {
			window.scrollTo(x, y);
		}
	}

};