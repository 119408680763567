(function($) {

    $('.js-filter-kjm').each(function() {

        var $module = $(this),
            $select = $module.find('select'),
            $items = $module.find('.js-filter-kjm-item:not([data-id="missing"])'),
            $itemMissing = $module.find('.js-filter-kjm-item[data-id="missing"]'),
            $trigger = $module.find('.js-filter-kjm-trigger>g'),
            elForm = $module.find('.js-filter-kjm-form').get(0);


        $select.on('_changed', function() {

            var id = $(this).val(),
                hasMatch = false;

            $items.each(function() {

                $(this).toggleClass('is-active', id === $(this).data('id'));

                if(id === $(this).data('id')) {

                    hasMatch = true;

                }

            });

            $itemMissing.toggleClass('is-active', !hasMatch && $select.val() !== null);

        });

        $trigger.on('click', function() {

            var id = ($(this).data('id') || '').toLowerCase();

            $select.val(id).trigger('change');

            QUEO.Utils.scrollToElement(elForm);

        });

        $module.find('.js-responsive-table').each(function() {

            var $table = $(this),
                $theadRow = $table.find('thead tr:eq(0)'),
                $tbodyRows = $table.find('tbody tr'),
                cols = [],
                _class;

            $theadRow.find('th,td').each(function(i) {

                _class = $(this).attr('class');

                cols.push(_class ? _class + '-mobile' : '');

            });

            $tbodyRows.each(function() {

                $(this).find('td').each(function(i) {

                    if(cols[i]) {

                        $(this).addClass(cols[i]);

                    }

                });

            });

        });

    });

})(window.jQuery);