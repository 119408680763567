(function ($) {

    var $formAddElements = $('.js-form-add-elements'),
        $toggleForm = $('.js-toggle-form'),
        $formFieldToggles = $('.js-form-field-toggle'),
        $d = $(document),
        $multipleFileUpload = $('.js-multiple-file-upload'),
        $fileInput = $('.js-input-file');

    // add/remove form fields
    if( $formAddElements.length > 0 ) {

        $formAddElements.each(function (i) {

            var $targetContainer = $(this),
                skeletonSelectorClass = 'js-form-add-elements-clone',
                $skeletons = $targetContainer.find('.' + skeletonSelectorClass),
                $skeleton = $skeletons.first().clone().removeClass(skeletonSelectorClass),
                indexToken = 'X#X',
                inputIdPreset = i + '_' + Date.now(),
                inputIdCount = 0,
                $addButton = $targetContainer.find('.js-form-add-elements-add'),
                $addButtonContainer = $addButton.closest('.ph-fieldset__wrapper-outer');

            // detach any skeleton from DOM to avoid them to get send
            $skeletons.remove();

            // save skeleton input-names to "data-name", so we can rebuild the correct names after append/removing additional items
            $skeleton.find('input, select').each(function () {
                $(this).data('name', $(this).attr('name'));
            });

            $addButton.on('click', function () {

                var $clone = $skeleton.clone(true);
                handleExistingOrNewEntry($clone);
                updateInputNameIndex();
                QUEO.select.init();

            });

            $targetContainer.find('.ph-fieldset__wrapper-outer').not($addButtonContainer).each(function () {

                handleExistingOrNewEntry($(this));

            });

            function updateInputNameIndex() {

                $targetContainer.find('.ph-fieldset__wrapper-outer').not($addButtonContainer).each(function (index) {

                    $(this).find('input:not(.js-form-select-queo-input), select').each(function () {

                        var $input = $(this);

                        // Note: for some reason an error occurs here on PL example page (if you "add" on second form example with errors)
                        // this must be because of some name/ids not being set correct in JSON, because in TYPO3-environment it simply works?
                        $input.attr('name', $input.data('name').replace(indexToken, index));
                    });

                });
            }

            function updateIdAndLabelForInput() {

                var $input = $(this),
                    $formfield = $input.closest('.ph-formfield'),
                    currentId = $input.attr('id');

                if(currentId) {

                    var newId = 'idx' + (inputIdPreset + '_' + inputIdCount++);
                    $input.attr('id', newId);
                    $formfield.find('label[for=' + currentId + ']').attr('for', newId);

                }
            }

            function applyRemoveAction($html) {

                $html.find('.js-form-remove-elements-remove').on('click', function () {

                    $html.remove();
                    updateInputNameIndex();

                });

            }

            function handleExistingOrNewEntry($html) {

                applyRemoveAction($html);
                $html.find('input:not(.js-form-select-queo-input), select').each(updateIdAndLabelForInput);
                $html.addClass('js-form-add-elements-container').insertBefore($addButtonContainer);

            }

        })

    }

    // toggle forms
    if( $toggleForm.length > 0 ) {

        $toggleForm.on('_changed', function () {

            var $t = $(this),
                $container = $t.closest('.js-toggle-forms'),
                val = this.value;

            $container.find('form[data-form-toggle-id]').each(function() {

                $(this).toggleClass('is-hidden', $(this).data('form-toggle-id') !== val);

            });

        }).trigger('change'); // fire trigger to ensure only the active form is visible
    }

    // toggle field via checkbox
    if( $formFieldToggles.length > 0) {

        $formFieldToggles.each(function() {

            var $t = $(this),
                toggleWhat = $t.is('[data-toggle-disabled]') ? 'disabled' : 'visibility',
                $formFieldToggle = $t.find('input[type="checkbox"],input[type="radio"]'),
                toggleFieldId = $t.data('toggle-field-id');

            $formFieldToggle
                // bind events
                .on('change _initFormFieldToggle', toggleHandler)
                // trigger init event...
                .trigger('_initFormFieldToggle')
                // ...and remove init event from trigger
                .off('_initFormFieldToggle', toggleHandler);

            function toggleHandler() {

                var $t = $(this),
                    type = $t.attr('type'),
                    toggle = $t.data('toggle'),
                    toggleChildren = $t.data('toggle-children'),
                    $toggleFieldId,
                    isChecked = $t.is(':checked'),
                    condition;

                // do nothing if ID is not present
                if(!toggleFieldId) {

                    console.warn('no toggle-field-id for ', this);
                    return;

                }

                switch (type) {

                    case 'radio':

                        // if-condition to prevent toggling if radio group is stateless (no item is checked) on init
                        if(typeof toggle === 'boolean' && isChecked) {

                            condition = !toggle;

                        }

                        break;

                    case 'checkbox':

                        condition = (toggle === 'checked' && !isChecked) || (toggle === 'unchecked' && isChecked);

                        break;

                }

                // toggle if a condition was defined in switch
                if(condition !== undefined) {

                    // toggleFieldId can also be a list of id's hence the split/join
                    $toggleFieldId = $('#' + toggleFieldId.split(',').join(',#'));

                    switch(toggleWhat) {

                        case 'disabled':

                            if(toggleChildren) {

                                $toggleFieldId.each(function() {

                                    var $t = $(this),
                                        $children = $t.find('input,textarea,select,button'),
                                        disabled = $t.attr('id') === toggleChildren ? !condition : true;

                                    // set disabled (and also override data-disabled-previously in case this is inside visibility toggle too)
                                    $children.attr('disabled', disabled).data('disabled-previously', disabled);

                                });

                            } else {

                                // toggle disabled attribute (note: re-reversed condition)
                                $toggleFieldId.attr('disabled', !condition);

                            }

                            break;

                        case 'visibility':

                            // toggle visibility of container and disabled attribute of contained fields
                            toggleDisabled($toggleFieldId.toggleClass('is-hidden', condition), condition);

                            // toggle class on field wrapper
                            $t.closest('.ph-formfield').toggleClass('sub-is-open', !$toggleFieldId.hasClass('is-hidden'));

                            break;
                    }

                }

            }

        });

    }

    // multiple file upload
    if( $multipleFileUpload.length > 0 ) {

        var multipleFileUploadMaxFiles = 4;

        $multipleFileUpload.each(function() {

            var $t = $(this),
                $inputFile = $t.find('input[type="file"]'),
                itemsAddedText = $inputFile.parent().data('added-text'),
                $skeleton = $t.find('.js-multiple-file-skeleton'),
                $skeletonItem = $skeleton.find('.js-multiple-file-item'),
                $skeletonBlob = $skeleton.find('.js-multiple-file-blob'), // note: blob stands for "binary large object"
                $skeletonFilename = $skeleton.find('.js-multiple-file-filename'),
                skeletonCfg = {
                    additional: $skeleton.find('.js-multiple-file-additional').get() || [],
                    indexReplacement: $skeleton.data('index-replacement'),
                    blob: $skeletonBlob.data('name'),
                    filename: $skeletonFilename.data('name')
                },
                $container = $t.find('.js-multiple-file-container'),
                initialFilesCount,
                filesCount;

            // cleanup skeleton
            $skeletonBlob.add($skeletonFilename).removeAttr('data-name');

            // treat additional hidden input fields
            $container.find('.js-multiple-file-item .js-multiple-file-additional').each(function() {

                var $t = $(this),
                    name = $t.attr('name'),
                    partial;

                skeletonCfg.additional.forEach(function(element) {

                    partial = $(element).data('name').split(skeletonCfg.indexReplacement);

                    if(partial.length === 2 && name.split(partial[0]).length === 2 && name.split(partial[1]).length === 2) {

                        $t.data('match-name', $(element).data('name'));

                    }

                });

            });

            $inputFile.on('change', function() {

                // using FileReader API
                if(this.files && this.files.length > 0) {

                    var acceptAttr = $inputFile.attr('accept'),
                        acceptedFiles = Array.from(this.files).filter(function(file) { return isAccepted(file, acceptAttr); });

                    // set filesCount (will be decreased in onload function to tell if all files have been transmitted to the browser and re-indexing can be applied)
                    filesCount = initialFilesCount = acceptedFiles.length;

                    acceptedFiles.forEach(function(file, index) {

                        addItem(file, file.name);

                        // empty file input once all items were added
                        // Note: technically this is not necessary, but browsers display text with all current files
                        // on hover and this is misleading when user adds again more files. Since they are not added but
                        // overwrite the input value which shows text on hover with only those newly added files.
                        if(index + 1 === filesCount) {

                            $inputFile.val('');

                        }

                    });

                }

            }).parent().on('dragenter dragleave dragend drop', function(e) {

                $(this).toggleClass('is-dragged-over', e.type === 'dragenter');

            });

            function addItem(file, filename) {

                var reader = new FileReader();

                // bind reader onload event
                reader.onload = function (e) {

                    // start to fade-in added-text when it is the first item
                    if(filesCount === initialFilesCount) {

                        // make input file not clickable during fade-in/-out and start fade-in/-out of added-text
                        // and disable file-upload in the meantime
                        $inputFile.addClass('show-notice').css('pointer-events', 'none')
                            // wrapper
                            .parent()
                            // update text with number of items added
                            .attr('data-added-text', itemsAddedText.replace('{num}', initialFilesCount))
                            // set class to kick off css fade-in of added text
                            .addClass('added-items')
                            // bind transitionend (Note: will not work in IE11 because transition happens on :pseudo element - modern browsers do emit such events on actual element!)
                            .one('transitionend', function() {
                                // start fade-out of added text
                                // Note: for some reason another $.one('transitionend') doesn't work for all browsers (event is fired way sooner) hence the setTimeout based on CSS prop
                                $(this).removeClass('added-items').each(function() {

                                    var delay = getTiming(this, 'transition-delay');

                                    // enable file-upload again
                                    setTimeout(function() {

                                        $inputFile.removeClass('show-notice').css('pointer-events', 'all');

                                    }, delay);

                                });

                            });

                    }

                    var $addItem = $skeletonItem.clone();

                    // treat additional hidden input fields
                    $addItem.find('.js-multiple-file-additional').each(function() {

                        $(this).data('match-name', $(this).data('name')).removeAttr('data-name');

                    });
                    // set filename on input and as plaintext on div node
                    $addItem.find('.js-multiple-file-filename').each(function() {

                        var $t = $(this);

                        if(this.nodeName.toLowerCase() === 'input') {

                            $t.attr('value', filename);

                        } else {

                            $t.text(filename);

                        }

                    });
                    // add file blob to input
                    $addItem.find('.js-multiple-file-blob').val(e.target.result);

                    // append item to container and toggle limited text if items count is greater than allowed
                    $container.append($addItem).toggleClass('is-limited', $container.find('.js-multiple-file-item').length > multipleFileUploadMaxFiles);

                    // if no items are remaining the index must be updated
                    if(--filesCount === 0) {

                        $container.trigger('_updateIndex');

                    }

                };

                // Note: emits onload event after reading file
                reader.readAsDataURL(file);

            }

            // helper to get timing (transition-duration /-delay) from pseudo element
            function getTiming(el, prop) {

                var timing = window.getComputedStyle(el, ':after').getPropertyValue(prop);

                if(timing.match(/ms/g)) {

                    timing = timing.replace('ms', '');

                } else {

                    timing = timing.toString().replace('s', '') * 1000;

                }

                return timing;

            }

            // bind listener for updating index of input names (also toggles disabled for up/down controls)
            $t.on('_updateIndex', function() {
                var $items = $container.find('.js-multiple-file-item'),

                    $firstUpControl = $items.eq(0).find('.js-multiple-file-upload-control[data-action="up"]'),
                    $lastDownControl = $items.eq(-1).find('.js-multiple-file-upload-control[data-action="down"]'),
                    $allOtherUpDownControls = $items.find('.js-multiple-file-upload-control[data-action="up"],.js-multiple-file-upload-control[data-action="down"]').not($firstUpControl).not($lastDownControl),

                    itemsCount = $items.length;

                // accessibility: enable up/down for all controls but not for first/last (handled in following each loop)
                $allOtherUpDownControls.attr('disabled', false);

                // show limited text if items count is greater than allowed
                $container.toggleClass('is-limited', itemsCount > multipleFileUploadMaxFiles);

                $items.each(function(i) {

                    var $t = $(this),
                        $inputAdditional = $t.find('.js-multiple-file-additional'),
                        $inputBlob = $t.find('.js-multiple-file-blob'),
                        $inputFilename = $t.find('.js-multiple-file-filename');

                    // only update index for allowed number of items
                    if(i + 1 <= multipleFileUploadMaxFiles) {

                        // update index inside name of inputs
                        $inputAdditional.each(function() {

                            updateIndex($(this), $(this).data('match-name'), i);

                        });
                        updateIndex($inputBlob, skeletonCfg.blob, i);
                        updateIndex($inputFilename, skeletonCfg.filename, i);

                    }
                    // remove name attribute for all following elements to prevent uploading to server
                    else {

                        $inputAdditional.removeAttr('name');
                        $inputFilename.removeAttr('name');
                    }

                });

                // update index in input name attribute
                function updateIndex($input, name, index) {

                    $input.each(function() {

                        var $t = $(this);

                        if($t.get(0).nodeName.toLowerCase() !== 'input') {

                            return;

                        }

                        name = name.replace(skeletonCfg.indexReplacement, index);

                        $t.attr('name', name);

                    });

                }

            }).trigger('_updateIndex'); // do trigger _updateIndex for init (setting controls)

        });

        // bind click handler for multiple file upload controls
        $d.on('click', '.js-multiple-file-upload-control', handleMultipleFileControl);

        // handle multiple file upload controls
        function handleMultipleFileControl() {

            var $t = $(this),
                action = $t.data('action'),
                $container = $t.closest('.js-multiple-file-container'),
                $item = $t.closest('.js-multiple-file-item'),
                $form = $item.closest('form'),
                updateIndex = true;

            switch(action) {

                case 'up':

                    // move item up
                    $item.prev().before($item);

                    break;

                case 'down':

                    // move item down
                    $item.next().after($item);

                    break;

                case 'remove':

                    // remove item
                    $item.remove();

                    break;

            }

            // if flag is set emit event to update index
            if(updateIndex) {

                $container.trigger('_updateIndex');

            }

        }

        // adapted from here: https://github.com/dropzone/dropzone/blob/v5.9.2/src/dropzone.js#L1951
        function isAccepted(file, acceptedFiles) {

            // If there are no accepted mime types, it's OK
            if(!acceptedFiles) {

                return true;

            }

            acceptedFiles = acceptedFiles.split(",");

            var mimeType = file.type,
                baseMimeType = mimeType.replace(/\/.*$/, "");

            return acceptedFiles.some(function(validType) {

                validType = validType.trim();

                if(validType.charAt(0) === ".") {

                    if(file.name.toLowerCase().indexOf(validType.toLowerCase(), file.name.length - validType.length) !== -1) {
                        return true;
                    }

                } else if(/\/\*$/.test(validType)) {

                    // This is something like a image/* mime type
                    if(baseMimeType === validType.replace(/\/.*$/, "")) {
                        return true;
                    }

                } else {

                    if(mimeType === validType) {
                        return true;
                    }

                }

            });

        }

    }

    // default file input (show remove icon on input when it contains a file)
    $fileInput.each(function() {

        var $input = $(this).find('input[type="file"]');

        $input.on('change', function() {

            $(this).addClass('contains-file');

        });

        $(this).find('.js-clear-file-input').on('click', function() {

            $input.val('').removeClass('contains-file');

        });

        if($input.get(0).files.length > 0) {

            $input.trigger('change');

        }

    });

    // force disabling of contained fields when hidden, but set proper disabled state on fields when visible
    // Note: used for $formFieldToggles and $formFieldSwitch
    function toggleDisabled($container, condition) {

        $container.find('input,textarea,select,button').each(function() {

            var $field = $(this);

            if(condition) {

                $field.data('disabled-previously', $field.attr('disabled')).attr('disabled', true);

            } else {

                $field.attr('disabled', $field.data('disabled-previously') || false);

            }

        });

    }

    // Note: removes any error stying/message on submit (only used for special case where submit would start download of
    // generated pdf based on form data)
    $d.on('click', '.js-remove-errors-on-click button[type="submit"], .js-remove-errors-on-click input[type="submit"]', function() {
        $(this).closest('form').find('.has-error').removeClass('has-error').find('.ph-formfield__message').empty();
    });

})(window.jQuery);