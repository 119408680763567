var QUEO = window.QUEO || {};

(function(d, w) {

    QUEO.cIframe = {
        init: init
    };

    var iFrames = d.querySelectorAll('iframe.js-iframe-auto-height:not(.js-active)'),
        resizeBound;

    if(iFrames.length === 0) {
        return;
    }

    // bind onload event for iframe and set initial height
    iFrames.forEach(init);
    
    function init(iFrame) {

        iFrame.parentNode.classList.add('is-loading');
        iFrame.classList.add('js-active');

        // update iFrames with all current iframe's
        iFrames = d.querySelectorAll('iframe.js-iframe-auto-height.js-active');

        iFrame.addEventListener('load', function() {

            iFrame.parentNode.classList.remove('is-loading');
            iFrame.dataset.loaded = 'true';
            setHeight(iFrame, true);

        });

        // if flag was not set yet call bindResize
        if(!resizeBound) {

            bindResize();

        }

    }

    function bindResize() {

        resizeBound = true;

        // bind onresize event to update iframe height
        w.addEventListener('resize', function() {

            iFrames.forEach(function(iFrame) {

                // only set height when iframe content is actually loaded
                if(iFrame.dataset.loaded) {

                    setHeight(iFrame, false);

                }

            });

        });

    }

    // set height of iframe based on inner content height (checks for same-domain)
    function setHeight(iFrame, initial) {

        // check if on same domain and therefore allowed to access iframe document via javascript
        if(iFrame.contentDocument) {

            // initially set min-height to zero (typically it is set to 100vh via css) otherwise scrollHeight returns nonsense ;-)
            if(initial) {

                iFrame.contentDocument.body.style.minHeight = '0';

            }

            // set iframe content height on iframe
            iFrame.style.height = iFrame.contentDocument.body.scrollHeight + 'px';
            requestAnimationFrame(function() {
                requestAnimationFrame(function() {
                    iFrame.style.height = iFrame.contentDocument.body.scrollHeight + 'px';
                });
            });

        }

    }

})(document, window);