/*global jQuery:false */
var QUEO = window.QUEO || {};

(function(w, d){

	var _htmlClassList = d.querySelector('html').classList,
        _isResizeStart = true,
        _to;

	// remove initial no-* css classes
    _htmlClassList.remove('no-js');
    _htmlClassList.remove('no-transition');

	// toggle "no-touch" class based on user interaction with actual used device
    _htmlClassList.add('no-touch');
    w.addEventListener('touchstart', detectTouch);
    function detectTouch() {

        _htmlClassList.remove('no-touch');
        w.removeEventListener('touchstart', detectTouch);

        /**
         * Note: thanks to chrome, the following does not work, as "touch" sometimes(?!) also triggers a mouse-related event, sigh :(
         */

        // delay binding slightly, just to be safe
        // requestAnimationFrame(function() {
        //     requestAnimationFrame(function() {
        //         w.addEventListener('mouseover', detectMouse);
        //     });
        // });

    }
    // function detectMouse() {
    //
    //     _htmlClassList.add('no-touch');
    //     w.removeEventListener('mouseover', detectMouse);
    //
    //     // delay binding slightly, just to be safe
    //     requestAnimationFrame(function() {
    //         requestAnimationFrame(function() {
    //             w.addEventListener('touchstart', detectTouch);
    //         });
    //     });
    //
    // }

	// implements custom resize handler (_onResizeStart + _onResizeEnd fired on window) and toggles state class "is-resizing" on HTML accordingly
    w.addEventListener('resize', function() {

        clearTimeout(_to);

	    if(_isResizeStart) {

	        _isResizeStart = false;
            w.dispatchEvent(new Event('_onResizeStart'));
            _htmlClassList.add('is-resizing');

        }

        _to = setTimeout(onResizeEnd, 200);

    });
	function onResizeEnd() {

	    _isResizeStart = true;
        w.dispatchEvent(new Event('_onResizeEnd'));
        _htmlClassList.remove('is-resizing');

    }

    /**
     * provided event-function parameters:
     *     - e: event
     *     - container: the added container
     */
    $(document).on('frm-repeatable-after-add-container', function () {
        window.QUEO.select.init();
    });

})(window, document);