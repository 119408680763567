(function ($) {

    $('.js-chart:not(.js-initialized)').addClass('js-initialized').each( function (chartIndex) {

        var $module = $(this),
            $moduleCloned = $module.clone().addClass('print-only'),
            animationDuration = 3000,
            getOrCreateLegendList = function (chart, id){
                var legendContainer = document.querySelector('[data-legend-id="' + id + '"]'),
                    listContainer = legendContainer.querySelector('ul');

                if (!listContainer) {
                    listContainer = document.createElement('ul');

                    legendContainer.appendChild(listContainer);
                }

                return listContainer;
            },
            htmlLegendPlugin = {
                id: 'htmlLegend',
                afterUpdate: function (chart, args, options) {
                    var ul = getOrCreateLegendList(chart, options.containerID);

                    // Remove old legend items
                    while (ul.firstChild) {
                        ul.firstChild.remove();
                    }

                    // Reuse the built-in legendItems generator
                    var items = chart.options.plugins.legend.labels.generateLabels(chart);

                    items.forEach(function (item) {
                        var li = document.createElement('li');

                        // Color box
                        var boxSpan = document.createElement('span');
                        boxSpan.style.setProperty("background-color", item.fillStyle, "important");
                        boxSpan.style.borderColor = item.strokeStyle;

                        // Text
                        var textContainer = document.createElement('p');

                        var text = document.createTextNode(item.text);
                        textContainer.appendChild(text);

                        li.appendChild(boxSpan);
                        li.appendChild(textContainer);
                        ul.appendChild(li);
                    });
                }
            };

        // insert cloned module (for print version) before module
        $module.before($moduleCloned);

        // append container for custom html legend to module
        $module.append($('<div/>').addClass('m-chart__item-legend').attr('data-legend-id', 'legend-container-' + chartIndex));
        $moduleCloned.append($('<div/>').addClass('m-chart__item-legend').attr('data-legend-id', 'print-legend-container-' + chartIndex));

        // extend $module with $moduleCloned
        $module = $module.add($moduleCloned);

        // Instead of checking scroll & element in view
        // creating an intersectionObserver, which sends a callback, when element is in view
        if ('IntersectionObserver' in window) {
            //console.log("supports IntersectionObserver");

            var observer = new IntersectionObserver(handleIntersection, {
                    root: null, // null is viewport,
                    rootMargin: '0px 0px 0px',
                    threshold: 0.5
                });

            observer.observe(this);

        } else {
            //console.log("no support IntersectionObserver");

            initChart();
        }

        // init print-only chart always (only used and visible for printing page)
        initChart(true);

        function initChart(printOnly) {

            var $canvas = $module.filter((printOnly ? '.print-only' : ':not(.print-only)')).find('.js-chart-canvas'),
                chartType = $canvas.data('type') || 'bar',
                unit = $canvas.data('unit'),
                stacked = $canvas.data('stacked'),
                isPie =  chartType === 'doughnut',
                chartLabels = $canvas.data('labels') || [],
                chartDatasets = $canvas.data('datasets') || [],

                dataCount = chartDatasets[0] && chartDatasets[0].hasOwnProperty('data') ? chartDatasets[0].data.length : 0,

                colorsPie = ["#55A51C","#00a9dc","#004799","#5c2483","#ae0f0a","#ee7203",'#f7a600','#646464'],
                colorsBar = chartDatasets.length < 3 ? ["#55A51C", "#8E969C"] : ["#55A51C","#00a9dc","#004799","#ee7203",'#f7a600','#646464','#3c7414','#187f9f','#d55510','#5c2483','#46c6ed','#4274af','#eb5622','#52b909'],
                colors = isPie ? colorsPie : colorsBar,

                multiDatasets = chartDatasets.length > 1,

                // tooltip
                tooltipItemValue = '',
                tooltipItemLabel = '',
                tooltipItemDatasetLabel = '',
                tooltipId = 'chartjs-tooltip-' + chartIndex;

            $module.toggleClass('is-pie', isPie);

            colors = extendColors(chartDatasets[0].data.length, colors, 40);

            chartDatasets.forEach(function (data, i) {

                data.backgroundColor = isPie ? colors : colors[i];
            })

            // default config
            var config = {
                type: chartType,
                data: {
                    labels: chartLabels,
                    datasets: chartDatasets
                },
                options: {
                    animation: false,
                    plugins: {
                        title: {
                            display: false
                        },
                        htmlLegend: {
                            // ID of the container to put the legend in
                            containerID: (printOnly ? 'print-' : '') + 'legend-container-' + chartIndex,
                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false,
                        }
                    }
                },
                plugins: [
                    htmlLegendPlugin
                ]
            };

            // extend/overwrite config parts for non-print version
            if(!printOnly) {

                config.options.plugins.tooltip = Object.assign(config.options.plugins.tooltip, {
                    enabled: false,
                    callbacks: {
                        label: function (tooltipItem) {
                            tooltipItemValue = tooltipItem.formattedValue + (unit ? ' ' + unit : '');
                            tooltipItemLabel = tooltipItem.label;
                            tooltipItemDatasetLabel = tooltipItem.dataset.label;

                        }
                    },
                    external: function (context) {
                        var tooltipModel = context.tooltip,
                            chartCanvas = context.chart.canvas;
                        createTooltip(tooltipId, chartCanvas, tooltipModel, tooltipItemValue, tooltipItemLabel, tooltipItemDatasetLabel, multiDatasets);
                    }
                });
                config.options.animation = {
                    duration: animationDuration
                };

            }

            // if type bar-chart & stacked then extend config
            if( !isPie && stacked === true ) {
                Object.assign(config.options, {
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true
                        }
                    }
                })
            }

            new Chart($canvas, config);

        }
        function createTooltip(id, canvas, ttModel, value, label, datasetLabel, isMultiData) {

            // tooltip
            var tooltipEl = document.getElementById(id);

            // Create element on first render
            if (!tooltipEl) {

                tooltipEl = document.createElement('div');
                tooltipEl.id = id;
                tooltipEl.className = 'm-chart__item-tooltip';
                tooltipEl.innerHTML = '<div class="m-chart__item-tooltip-inner"></div>';
                $(canvas).after(tooltipEl);

            } else if(ttModel.opacity === 0) {

                // tooltip hide - only set opacity to 0 and simply quit
                tooltipEl.style.opacity = '0';
                return;

            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform', 'left', 'right', 'center');
            if (ttModel.yAlign) {
                tooltipEl.classList.add(ttModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            // FIX: 'center', huh? This view mode is only used for bar chart on column(s) with max value???
            if(ttModel.xAlign && ttModel.xAlign === 'center') {

                // in this case determine left/right alignment based on caretX position
                ttModel.xAlign = ttModel.caretX <= ttModel._chart.width * 0.5 ? 'left' : 'right';

            }
            if(ttModel.xAlign) {
                tooltipEl.classList.add(ttModel.xAlign);
            }

            // set text
            if ( ttModel.body ) {
                var tooltipContent = tooltipEl.querySelector('.m-chart__item-tooltip-inner'),
                    datasetLabelHtml = '<p class="m-chart__item-tooltip-dataset-label">' + datasetLabel + '</p>',
                    valueHtml = '<p class="m-chart__item-tooltip-value">' + value + '</p>',
                    labelHtml = '<p class="m-chart__item-tooltip-label">' + label + '</p> ';

                // if chart is stacked, show dataset-label
                if( canvas.dataset.stacked && isMultiData){
                    tooltipContent.innerHTML = datasetLabelHtml + valueHtml + labelHtml;
                } else {
                    tooltipContent.innerHTML = valueHtml + labelHtml;
                }
            }

            // Display, position, and set styles for font
            tooltipEl.style.opacity = ttModel.opacity;
            tooltipEl.style.position = 'absolute';
            if(ttModel.xAlign && ttModel.xAlign === 'right') {

                tooltipEl.style.left = 'auto';
                tooltipEl.style.right = (ttModel._chart.width - ttModel.caretX) + 'px';

            } else {

                tooltipEl.style.left = ttModel.caretX + 'px';
                tooltipEl.style.right = 'auto';

            }
            if( canvas.dataset.type === 'bar') {
                tooltipEl.style.top = (ttModel.caretY + 5) + 'px';
            } else {
                tooltipEl.style.top = ttModel.caretY + 'px';
            }

        }
        function lightenDarkenColor(col,amt) {
            var usePound = false;
            if ( col[0] === "#" ) {
                col = col.slice(1);
                usePound = true;
            }

            var num = parseInt(col,16);

            var r = (num >> 16) + amt;

            if ( r > 255 ) r = 255;
            else if  (r < 0) r = 0;

            var b = ((num >> 8) & 0x00FF) + amt;

            if ( b > 255 ) b = 255;
            else if  (b < 0) b = 0;

            var g = (num & 0x0000FF) + amt;

            if ( g > 255 ) g = 255;
            else if  ( g < 0 ) g = 0;

            return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
        }
        function extendColors(dataCount, colors, amount) {

            if(dataCount > colors.length) {
                var _colors = [],
                    _amount = amount;
                for(var i = colors.length; i < dataCount; i++) {
                    if(i !== colors.length && (i % colors.length) === 0) {
                        _amount += amount;
                    }
                    _colors.push(lightenDarkenColor(colors[i % colors.length], _amount));
                }
                return colors.concat(_colors);
            }
            return colors;
        }
        function handleIntersection(entries) {
            // console.log('entries: ', entries);
            entries.forEach(function (entry){
                if( entry.isIntersecting) {
                    initChart();
                    observer.disconnect();
                }
            })
        }
    })

})(window.jQuery);
