var QUEO = window.QUEO || {};

QUEO.Modal = (function($, d, w) {

    var $d = $(document),
        $b = $('body'),
        $currentModal;

    // watch focus of triggers to be able to restore focus after close
    $d.on('focus', '.js-modal-trigger', function(e) {

        var $trigger = $(this),
            type = $trigger.data('trigger') || 'close',
            $modal = type === 'open' ? $($trigger.data('target') + '.js-modal') : $trigger.closest('.js-modal');

        if(type === 'open') {

            $modal.data('restore-focus', d.activeElement === $trigger.get(0) ? $trigger : null);

        }

    });
    // handle trigger click to open/close modal
    $d.on('click', '.js-modal-trigger', function() {

        var $trigger = $(this),
            type = $trigger.data('trigger') || 'close',
            $modal = $($trigger.data('target') + '.js-modal');

        if($modal.length === 0) {
            $modal = $trigger.closest('.js-modal');
        }

        $modal.toggleClass('is-open', type !== 'close');

        handleModal($modal);

    });

    function handleModal($modal) {

        $currentModal = $modal;

        // either set focus on modal or call close (and check if focus must be set on initial trigger-open button)
        if($modal.hasClass('is-open')) {

            $modal
                .addClass('is-init')
                .prepend('<div class="js-modal-fetch-focus" data-start tabindex="0" aria-hidden="true"/>')
                .append('<div class="js-modal-fetch-focus" data-end tabindex="0" aria-hidden="true"/>')
                .find('.js-modal-fetch-focus:eq(0)').focus();

            w.addEventListener('keydown', closeModalOnESC);

        } else {

            closeModal($modal);
            w.removeEventListener('keydown', closeModalOnESC);

        }

    }

    // handle layer click to close modal
    $d.on('click', '.js-modal', function(e) {

        var $modal = $(this),
            $target = $(e.target);

        if($target.is('.js-modal-content') || $target.closest('.js-modal-content').length > 0) {

            return;

        }

        closeModal($modal);

    });

    // closes modal and restore focus if necessary
    function closeModal($modal) {

        var $restoreFocus = $modal.data('restore-focus');

        // close modal
        $modal.removeClass('is-open').trigger('modalClose');
        $modal.find('.js-modal-fetch-focus').remove();

        if($restoreFocus) {

            $restoreFocus.focus();

        }

    }

    // closes modal on ESC (calls closeModal with $currentModal)
    function closeModalOnESC(e) {

        if(e.key === 'Escape') {

            w.removeEventListener('keydown', closeModalOnESC);
            closeModal($currentModal);

        }

    }

    // handler to constrain tab focus for opened modal
    $d.on('focus', '.js-modal .js-modal-fetch-focus', function() {

        var $t = $(this),
            $modal = $t.closest('.js-modal'),
            // find all focusable elements within modal, adapted from here: https://zellwk.com/blog/keyboard-focusable-elements/
            $nextFocus = $modal.find('a,button,input,textarea,select,details').eq($t.data('start') === undefined ? 0 : -1);

        $nextFocus.focus();

        if($modal.hasClass('is-init')) {

            $modal.removeClass('is-init');
            $nextFocus.blur();

        }

    });

    // create a modal on the fly, example usage:
    // $.modal({headline:'my head hurts but', text:'<strong>is my mind</strong>'})
    $.modal = function(options) {

        var $modal = $('<div class="m-modal js-modal"></div>').on('modalClose', function(){
                setTimeout(removeModal, window.QUEO.Config.transitionDurationModal);
            }),
            $modalWrapper = $('<div class="m-modal__wrapper js-modal-content"></div>'),
            $modalClose = $('<div class="m-modal__close"><button class="js-modal-trigger" type="button" data-trigger="close"><span class="focus-item"></span></button></div>'),
            $modalContent = $('<div class="m-modal__content"></div>'),
            $modalContentWrapper = $('<div class="m-modal__content-wrapper"></div>'),
            headline = options.hasOwnProperty('headline') ? options.headline : false,
            text = options.hasOwnProperty('text') ? options.text : false,
            buttons = options.hasOwnProperty('buttons') ? options.buttons : [],
            buttonConfig,
            $buttons = $('<div class="m-modal__buttons"><div class="ph-button-container"><div class="ph-buttons-wrapper ph-buttons-wrapper--center"></div></div></div>'),
            $buttonsWrapper = $buttons.find('.ph-buttons-wrapper');

        if(headline) {

            // test if not wrapped in HTML element then wrap string with H3 element
            if(!/^(<).*(>)$/.test(headline)) {
                headline = '<h3>' + headline + '</h3>';
            }
            $modalContentWrapper.append(headline);

        }
        if(text) {

            // test if not wrapped in P, DIV, UL or OL then wrap string with P element
            if(!/^(<p|<section|<div|<ul|<ol).*(>)$/.test(text)) {
                text = '<p>' + text + '</p>';
            }
            $modalContentWrapper.append(text);

        }

        $modalContent.append($modalContentWrapper);

        if(buttons) {

            $(buttons).each(function() {

                buttonConfig = this;

                $buttonsWrapper.append(
                    $('<div class="ph-button-wrapper"></div>').append(
                        $('<button class="ph-button js-modal-trigger" data-trigger="close"></button>')
                            .toggleClass('ph-button--' + buttonConfig.buttonModifier, buttonConfig.hasOwnProperty('buttonModifier'))
                            .on('click', function() {
                                if(typeof buttonConfig.callback === 'function') {
                                    buttonConfig.callback(buttonConfig);
                                }
                            })
                            .append('<span class="focus-item">' + buttonConfig.buttonLabel + '</span>')
                    )
                );
            });

            $modalWrapper.append($buttons);

        }

        $modalWrapper.prepend($modalClose, $modalContent).appendTo($modal);

        $b.append($modal);

        requestAnimationFrame(function() {
            handleModal($modal.addClass('is-open'));
        });

        function removeModal() {

            // remove modal from DOM
            $modal.remove();

            // run onClose callback
            if(typeof options.onClose === 'function') {
                options.onClose();
            }

        }

    };

})(window.jQuery, document, window);