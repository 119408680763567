var QUEO = window.QUEO || {};

QUEO.SliderGallery = (function($) {

    function _init() {

        $('.js-slider-gallery:not(.js-initialized)').addClass('js-initialized').each(function (){

            var $module = $(this),
                $galleryThumbs = $module.find('.js-slider-gallery-thumbs'),
                $galleryTop = $module.find('.js-slider-gallery-top');

            if($galleryThumbs.length === 1 && $galleryTop.length === 1){

                // only initialize thumbs if there is more than one slide
                if($galleryThumbs.find('.js-swiper-slide').length > 1) {

                    // init current slider
                    var $galleryThumbsSlider = new Swiper($galleryThumbs, {
                        a11y: false,

                        on: {
                            init: function() {
                                $(this.el).trigger('init', this);
                            },
                            resize: function() {
                                $(this.el).trigger('resize', this);
                            }
                        },

                        spaceBetween: 12,
                        freeMode: true,
                        longSwipesRatio: 0.25, // the default 0.5 is a pain to swipe
                        slidesPerView: 4,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        watchOverflow: true
                    });

                } else {

                    // set a class to make single slide visible without swiper functionality / navigational elements
                    $galleryThumbs.addClass('no-swiper');

                }

                if($galleryTop.find('.js-swiper-slide').length > 1) {

                    // init current slider
                    new Swiper($galleryTop, {
                        a11y: true,
                        navigation: {
                            nextEl: '.swiper-button--next',
                            prevEl: '.swiper-button--prev',
                        },
                        on: {
                            init: function() {
                                $(this.el).trigger('init', this);
                            },
                            resize: function() {
                                $(this.el).trigger('resize', this);
                            }
                        },

                        longSwipesRatio: 0.25, // the default 0.5 is a pain to swipe
                        //slidesPerView: 1,
                        thumbs: {
                            swiper: $galleryThumbsSlider,
                        },
                        watchOverflow: true
                    });

                } else {

                    // set a class to make single slide visible without swiper functionality / navigational elements
                    $galleryTop.addClass('no-swiper');

                }

            }
        })



    }

    $(_init);

})(jQuery);