(function($, w, d) {

    var _$d = $(d);

    // bind elements for adjustFocusPosition (separated into different selector groups due to different outline offsets)
    _$d.on('focus', '.content button, .content .ph-button, .content input:not([type="hidden"]), .content select, .content textarea', {additionalOffset: 4}, adjustFocusPosition);
    // note: explicitly also observing links in footer for badly sighted users which are using browser zoom
    _$d.on('focus', '.content a:not(.ph-button), .m-footer a', {additionalOffset: 3}, adjustFocusPosition);

    // accessibility helper for focused (form) elements which may be underneath the sticky header (shift + tab)
    function adjustFocusPosition(e) {

        // skip if link/button implements own accessibility handing (like QUEO.SvgMap)
        if($(this).data('no-a11y')) {
            return;
        }

        var headerHeight = $('.js-header.is-collapsed .js-header-bar, .js-header:not(.is-collapsed)').outerHeight() || 0,
            elementOffset = $(this).offset().top,
            additionalOffset = e.data && e.data.hasOwnProperty('additionalOffset') ? e.data.additionalOffset : 0;

        // underneath the header therefore we adjust scroll position
        if(elementOffset - w.scrollY - headerHeight < 0) {
            w.scroll(w.scrollX,elementOffset - headerHeight - additionalOffset);
        }

    }

    // bind focus handling for checkbox inside label (when being clicked on)
    _$d.on('click', '.c-checkbox-switch', function(e) {

        if(!$(e.target).is('input')) {

            $(this).find('input').one('focus', _blur);

        }

    });
    // bind focus handling checkbox/radio (when being clicked on)
    _$d.on('click', 'input + label', function() {

        $(this).prev('input').one('focus', _blur);

    });
    function _blur(e) {

        e.preventDefault();

        $(this).blur();

    }

    // focus handling for links and buttons (those elements should receive :focus when being tabbed only)
    $(document).on('mousedown focus', 'a,button', function(e) {

        var $el = $(this),
            tabFocus = $el.data('tab-focus');

        // skip if link/button implements own accessibility handing (like QUEO.SvgMap)
        if($el.data('no-a11y')) {
            return;
        }

        if(typeof tabFocus === 'undefined') {

            setTabFocus($el, true);
            tabFocus = true;

        }

        if(e.type === 'mousedown') {

            setTabFocus($el, false);
            $el.blur();

        } else {

            if(!tabFocus) {

                $el.blur();

                // Note: following (rAF + return) is a fix for quirky Firefox behaviour when navigating back
                requestAnimationFrame(function() {

                    setTabFocus($el, true);

                });

                return;

            }

            setTabFocus($el, true);

        }

    });
    function setTabFocus($el, isActive) {

        $el.data('tab-focus', isActive);

    }

})(window.jQuery, window, document);