(function(w, d) {

    w.addEventListener('DOMContentLoaded', function() {

        var floaterTriggers = d.querySelectorAll('.js-floater .js-modal-trigger');

        // quit if no floater is not present
        if(floaterTriggers.length === 0) {
            return;
        }

        floaterTriggers.forEach(function(floaterTrigger) {

            // bind floater trigger
            floaterTrigger.addEventListener('click', invokeIframe);

            function invokeIframe() {

                // only needed initially therefore this event is removed immediately
                floaterTrigger.removeEventListener('click', invokeIframe);

                var container = d.querySelector(floaterTrigger.dataset.target),
                    elDiv = container ? container.querySelector('.js-floater-popup-iframe') : false,
                    elIframe = d.createElement('iframe');

                if(elDiv) {

                    elIframe.className = elDiv.className.replace('js-floater-popup-iframe', '').trim();
                    elIframe.height = 0;
                    elIframe.src = elDiv.dataset.src;

                    elDiv.parentNode.replaceChild(elIframe, elDiv);

                    QUEO.cIframe.init(elIframe);

                }

            }

        });

    });

})(window, document);