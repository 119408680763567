/*global jQuery:false */
var QUEO = window.QUEO || {};

QUEO.NotificationBanner = (function($) {

    var $b,
        $modules,
        notificationKey = 'kvwl-notification-banner-',
        allCurrentNotificationKeys = [];

    function _init() {

        $b = $('body');
        $modules = $b.find('.js-notification-banner:not(.js-initialized)');

        if($modules.length > 0) {

            $modules.each(function (){
                var $this = $(this),
                    thisNotificationKey = notificationKey + $this.data('hash'),
                    hideNotificationBanner = localStorage.getItem(thisNotificationKey),
                    hideNotificationBannerSessionStorage = sessionStorage.getItem(thisNotificationKey),
                    $closeTrigger = $this.find('.js-close-trigger'),
                    localStorageStatus = get_local_storage_status();

                $this.addClass('js-initialized');

                if ( hideNotificationBanner || hideNotificationBannerSessionStorage) {
                    $this.css({'display':'none'});
                } else {
                    $closeTrigger.on('click', function () {

                        $this.velocity('slideUp', { duration:300});

                        if ( localStorageStatus === 'available') {
                            localStorage.setItem(thisNotificationKey, true);
                        } else {
                            sessionStorage.setItem(thisNotificationKey, true);
                        }
                    });
                }
                allCurrentNotificationKeys.push(thisNotificationKey);
            })
        }
        clearOldNotificationKeys(allCurrentNotificationKeys);
    }

    // returns 'available' if localStorage has space
    // returns 'full' if localStorage is full
    // https://dev.to/zigabrencic/web-browser-local-storage-16jh
    function get_local_storage_status() {
        var testLocalStorage = "testLocalStorage";
        try {
            // try setting an item
            localStorage.setItem("testLocalStorage", testLocalStorage);
            localStorage.removeItem("testLocalStorage");
        }
        catch(e)
        {
            // browser specific checks if local storage was exceeded
            if (e.name === "QUATA_EXCEEDED_ERR" // Chrome
                || e.name === "NS_ERROR_DOM_QUATA_REACHED" //Firefox/Safari
            ) {
                // local storage is full
                return "full";
            } else {
                try{
                    if(localStorage.remainingSpace === 0) {// IE
                        // local storage is full
                        return "full";
                    }
                }catch (e) {
                    // localStorage.remainingSpace doesn't exist
                }

                // local storage might not be available
                return "unavailable";
            }
        }
        return "available";
    }

    // removes unused/ old notification-key from localStorage
    function clearOldNotificationKeys (allCurrentNoFiKeysArr) {

        // get all keys of localStorage
        var allLocalStorageKeys = Object.keys(localStorage),
            i = allLocalStorageKeys.length,
            allNoFiKeys = [];

        // push only notification-keys in array
        while ( i-- ) {
            if ( allLocalStorageKeys[i].startsWith(notificationKey)) {
                allNoFiKeys.push(allLocalStorageKeys[i]);
            }
        }


        // compare allCurrentNotification-array with allNoFiKeys-array
        // if key doesnt exist in allNofiKeys, remove item from localStorage
        for(j = 0; j < allNoFiKeys.length; j++) {
            var found = $.inArray(allNoFiKeys[j], allCurrentNoFiKeysArr);
            if( (-1 === found) ) {
                localStorage.removeItem(allNoFiKeys[j]);
            }
        }
    }

    $(_init);

})(jQuery);