var QUEO = window.QUEO ||{};

QUEO.cTable = (function ($) {

    function init() {

        var $tables = $('.js-responsive-table:not(.js-initialized)').addClass('js-initialized'),
            $table,
            $thead,
            $theadCells,
            theadContent,
            $tbodyRows;

        $tables.each(function () {

            $table = $(this);
            $thead = $table.find('thead');
            $tbodyRows = $table.find('tbody tr');

            if( $thead.length > 0 ) {

                $table.addClass('is-responsive');

                $theadCells = $thead.find('td, th');

                if( $theadCells.length > 0 && $tbodyRows.length > 0 ){

                    $theadCells.each(function (columnIndex) {

                        theadContent = $theadCells[columnIndex].textContent.trim();

                        $tbodyRows.each(function () {

                            $(this).find('td').eq(columnIndex).attr('data-title', theadContent);

                        })

                    })
                }

            }

        });

    }

    $(init);

    return {
        init: init
    };

})(window.jQuery);