(function ($) {

    function init() {

        // Note: since it is using live-binds (handler is bound to document) it is not necessary to re-initialize when new content is inserted

        // bind accordion trigger/transition handler
        $(document).on('click', '.js-accordion-trigger:not(.is-animating)', triggerHandler).on('transitionend', '.js-accordion-content', transitionHandler);

        function triggerHandler(e) {

            // don't toggle accordion if target is tooltip
            if($(e.target).hasClass('js-tooltip')) {
                return;
            }

            var $trigger = $(this),
                $item = $trigger.closest('.js-accordion-item').toggleClass('is-open'),
                $content = $item.find('.js-accordion-content:eq(0)'),
                isOpen = $item.hasClass('is-open');

            // toggle accessibility switch if present
            if($item.attr('aria-expanded')) {
                $item.attr('aria-expanded', isOpen);
            }

            // if closed via trigger at content bottom scroll into view
            if(!isOpen && this !== $item.find('.js-accordion-trigger:eq(0)').get(0)) {

                moveIntoView($item.find('.js-accordion-trigger:eq(0)').get(0));

            }

            // postpone animation if item has initially 'is-open' set and therefore no style.height was set via JS
            if(!isOpen && $content.get(0).style.height === '') {

                $content.css({
                    height: getContentHeight(),
                    visibility: 'visible'
                });
                setTimeout(animate, 40);

            } else {

                animate();

            }

            function animate() {

                $trigger.addClass('is-animating');
                $content.prev().addClass('is-animating');

                // "toggle" height for css transition
                if(isOpen) {

                    $content.css('height', 0);
                    $content.css('height', getContentHeight());

                } else {

                    $content.css('height', getContentHeight());
                    $content.css('height', 0);

                }

            }

            function getContentHeight() {
                // note: outerHeight MINUS offset difference because child content might use a negative margin (to make content appear closer to trigger while preserving trigger spacing)
                return $content.children().outerHeight() - ($content.offset().top - $content.children().offset().top);
            }

        }
        function transitionHandler(e) {

            var onCollapseEnd = e.target === this,
                onFadeEnd = e.target === $(this).children().get(0);

            // ignore delegated transitionend events from children (caused by hover states, styled dropdowns etc.)
            if(!(onFadeEnd || onCollapseEnd)) {
                return;
            }

            var $content = $(this),
                $item = $content.closest('.js-accordion-item'),
                $trigger = $item.find('.js-accordion-trigger');

            // remove style and let handle css current state
            $content.css({
                height: '',
                visibility: ''
            });

            $trigger.removeClass('is-animating');
            $content.prev().removeClass('is-animating');

        }

        function moveIntoView(elTopTrigger) {

            var elHeader = document.querySelector('.js-header'),
                headerHeight = elHeader ? elHeader.clientHeight : 0,
                elOffset = QUEO.Utils.getOffsetTop(elTopTrigger.querySelector('.focus-item'));

            // only scroll into view if top trigger is not visible anymore
            if(window.scrollY + headerHeight > elOffset) {

                window.scrollTo({
                    left: window.scrollX,
                    top: elOffset - headerHeight,
                    behavior: "smooth"
                });

            }

        }

    }

    $(init);
})(window.jQuery);
