(function(d, w) {

    var elHeader = d.querySelector('.js-header');

    if(!elHeader) {
        return;
    }

    var elLayerTriggers = elHeader.querySelectorAll('.js-header-trigger'),
        elFirstLevelTriggers = elHeader.querySelectorAll('.js-header-nav-main>ul>li>.js-header-nav-main-trigger'),
        elNavTriggers = elHeader.querySelectorAll('.js-header-nav-main-trigger'),
        elLayers = elHeader.querySelectorAll('.js-header-layer'),
        elLayer,
        isLayerOpen,
        isAnyLayerOpen,
        elCurrentTrigger,
        whatAction = 'outsideClick';

    // setup main nav first level triggers (required only for desktop breakpoint)
    elFirstLevelTriggers.forEach(function(elTrigger) {

        // apply data-main-level on first level triggers - makes it easier to distinguish in CSS
        elTrigger.dataset.mainLevel = 'true';

        // elTrigger.parentElement.dataset.mainLevelItem = 'true';

        elTrigger.addEventListener('click', handleNavFirstLevelTrigger);
        elTrigger.addEventListener('close', handleNavFirstLevelTrigger);

    });

    w.addEventListener('_onResizeEnd', toggleScrollPrevention);
    toggleScrollPrevention();

    // toggles scroll prevention since horizontal nav requires different behaviour as burger nav
    function toggleScrollPrevention() {

        var elHorizontalNav = elHeader.querySelectorAll('.js-header-nav-main-trigger[data-main-level] + .m-header__dropdown > .m-header__dropdown-wrapper'),
            elBurgerNav = elHeader.querySelector('.m-header__nav-wrapper');

        if(isHorizontalNav()) {

            // unbind prevention for burger nav
            QUEO.Utils.removeScrollPrevention(elBurgerNav);

            // bind prevention for horizontal nav
            elHorizontalNav.forEach(function(elDropdown) {

                QUEO.Utils.applyScrollPrevention(elDropdown);

            });

        } else {

            // unbind prevention for horizontal nav
            elHorizontalNav.forEach(function(elDropdown) {

                QUEO.Utils.removeScrollPrevention(elDropdown);

            });

            // bind prevention for burger nav
            QUEO.Utils.applyScrollPrevention(elBurgerNav, true);

        }

    }

    // burger/search trigger (below desktop breakpoint)
    elLayerTriggers.forEach(function(elTrigger) {

        elTrigger.addEventListener('click', function() {

            setWhatAction('layerTrigger');

            elCurrentTrigger = this;
            elCurrentTrigger.classList.toggle('is-open');

            isAnyLayerOpen = false;
            elLayerTriggers.forEach(function(elTrigger) {

                if(elTrigger !== elCurrentTrigger) {

                    elTrigger.classList.remove('is-open');

                }

                isLayerOpen = elTrigger.classList.contains('is-open');

                if(isLayerOpen) {
                    isAnyLayerOpen = true;
                }

                toggleLayer(elTrigger.dataset.trigger, isLayerOpen);

            });

            elHeader.classList.toggle('is-layer-open', isAnyLayerOpen);

        });

    });

    // main nav (dropdown/accordion) triggers
    elNavTriggers.forEach(function(elNavTrigger) {

        elNavTrigger.addEventListener('click', handleNavTrigger);
        elNavTrigger.addEventListener('close', handleNavTrigger);

    });

    // outside click handling (below desktop breakpoint)
    elLayers.forEach(function(elLayer) {

        // prevent closing layer (only when it is below desktop breakpoint)
        elLayer.addEventListener('click', function(e) {

            if(!isHorizontalNav()) {

                setWhatAction('layerClick');

            }

        });

        // bind clicks on links/modal trigger and form submit to close all layers
        elLayer.querySelectorAll('a').forEach(bindLinks);
        elLayer.querySelectorAll('.js-modal-trigger').forEach(bindLinks);
        elLayer.querySelectorAll('form').forEach(function(elForm) {

            elForm.addEventListener('submit', function() {

                whatAction = 'formSubmit';
                outsideClickHandler();

            });

        });

    });

    // set whatAction flag for links to ensure everything is closed
    function bindLinks(elLink) {

        elLink.addEventListener('click', function() {

            setWhatAction('linkClick');

        });

    }

    // unset height after transition end
    d.querySelectorAll('.m-header__dropdown').forEach(function(elDropdown) {

        elDropdown.addEventListener('transitionend', function(e) {

            if(e.target === elDropdown) {

                elDropdown.style.height = '';

            }

        });

    });

    // close everything if window is resized, otherwise setting correct states would be a mess
    w.addEventListener('_onResizeStart', function() {

        whatAction = 'resizeEvent';
        outsideClickHandler();

    });

    // outside click handler
    d.addEventListener('click', outsideClickHandler);

    // special handling of first level accordion triggers for horizontal nav close any other opened accordion
    function handleNavFirstLevelTrigger(e) {

        // ignore if not horizontal nav
        if(!isHorizontalNav()) {
            return;
        }

        if(!this.parentElement.classList.contains('is-open')) {

            if(e.type === 'click') {

                isAnyLayerOpen = true;

            }

            elCurrentTrigger = this;

            // close any other open first level item
            elFirstLevelTriggers.forEach(function (elTrigger) {

                if(elTrigger !== elCurrentTrigger && elTrigger.parentElement.classList.contains('is-open')) {

                    elTrigger.dispatchEvent(new Event('close'));

                }

            });

        } else if(e.type === 'click') {

            isAnyLayerOpen = false;

        }

    }

    // toggles dropdown/accordion
    function handleNavTrigger() {

        setWhatAction('dropDownClick');

        var elCurrentDropdown = this.nextElementSibling,
            elCurrentContainer = this.parentElement,
            dropdownHeight = elCurrentDropdown.querySelector('.m-header__dropdown-wrapper').clientHeight + 'px',
            isDropdownClosed = elCurrentContainer.classList.contains('is-open');

        elCurrentDropdown.style.height = isDropdownClosed ? dropdownHeight : '0px';

        requestAnimationFrame(function() {

            requestAnimationFrame(function() {

                elCurrentContainer.classList.toggle('is-open', !isDropdownClosed);
                elCurrentDropdown.style.height = isDropdownClosed ? '0px' : dropdownHeight;

            });

        });

    }

    // closes all layers, dropdowns and removes is-open states where necessary
    function outsideClickHandler() {

        if(['outsideClick', 'linkClick', 'formSubmit', 'resizeEvent'].indexOf(whatAction) !== -1) {

            if(isAnyLayerOpen) {

                // remove is-open state for triggers
                if(isHorizontalNav() || whatAction === 'resizeEvent') {

                    elFirstLevelTriggers.forEach(function (elFirstLevelTrigger) {

                        if(elFirstLevelTrigger.parentElement.classList.contains('is-open')) {

                            elFirstLevelTrigger.dispatchEvent(new Event('close'));

                        }

                    });

                }

                elLayerTriggers.forEach(function(elLayerTrigger) {

                    elLayerTrigger.classList.remove('is-open');

                });

                // close layers
                elLayers.forEach(function(elLayer) {

                    elLayer.classList.remove('is-open');

                });

                isAnyLayerOpen = false;

                elHeader.classList.remove('is-layer-open');

            }

            whatAction = 'outsideClick';

            return;

        }

        whatAction = 'outsideClick';

    }

    // toggles layer (main nav / search) when below desktop viewport
    function toggleLayer(type, isLayerOpen) {

        if(type) {

            elLayer = elHeader.querySelector('.js-header-layer[data-layer="' + type + '"]');

            if(elLayer) {

                elLayer.classList.toggle('is-open', isLayerOpen);

            }

        }

    }

    // helper to detect which nav is currently used
    function isHorizontalNav() {

        return w.innerWidth > QUEO.Config.breakpointLaptop;

    }

    // helper for determination of what was actually clicked
    function setWhatAction(action) {

        if(whatAction === 'outsideClick') {

            whatAction = action;

        }

    }

})(document, window);