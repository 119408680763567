var QUEO = window.QUEO || {};

QUEO.cTooltip = (function ($) {

    function init() {

        $('.js-tooltip:not(.js-initialized)').addClass('js-initialized').each(function () {

            var $this = $(this),
                $tooltipText = $this.find('.c-tooltip__text'),
                content = $tooltipText.html();

            $this.tooltipster({
                functionInit: function(instance){
                    instance.content(content);
                },
                functionReady: function(){
                    $tooltipText.attr('aria-hidden', false);
                },
                functionAfter: function(){
                    $tooltipText.attr('aria-hidden', true);
                },
                maxWidth: 300,
                trigger: 'custom',
                triggerOpen: {
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    mouseleave: true,
                    tap: true
                }
            }).on('focus blur', function(e) {
                $this.tooltipster(e.type === 'focus' ? 'open' : 'close');
            });

        });

    }
    $(init);

    return {
        init: init
    };
})(jQuery);
