var QUEO = window.QUEO || {};

QUEO.MediaPlayer = (function ($) {
    var _$b = $('body');

    // Set default strings (mejs' default i18n options don't seem to work in the context of this Pattern Lab)
    $.extend(mejs.MepDefaults, {
        playText: 'Abspielen',
        pauseText: 'Pausieren',
        muteText: 'Ton ausschalten',
        unmuteText: 'Ton anschalten',
        allyVolumeControlText: 'Pfeiltasten Aufwärts/Abwärts benutzen, um Lautstärke anzupassen'
    });

    function init() {

        _$b.find('audio:not(.js-applied),video:not(.js-applied)').addClass('js-applied').each(function() {

            var $player = $(this),
                $slider = $player.closest('.js-swiper');

            if($player.is('video') && $slider.length !== 0) {

                // add svg element to maintain 16:9 ratio (mobile breakpoint)
                $player.before('<svg class="c-video__shaper" height="450" width="800"/>');

            }

            $player.mediaelementplayer({
                'stretching': 'responsive'
            });

        });

    }

    init();

    return {
        init: init
    };
})(window.jQuery);