var QUEO = window.QUEO || {};

QUEO.select = (function ($, w, d) {
    var $w = $(w),
        $d = $(d),
        queoSelectCount = 0;

    function init() {

        $('.js-form-select-queo:not(.js-initialized)').addClass('js-initialized').each(function(i) {

            var $component = $(this),
                listId = 'queoSelectList_' + queoSelectCount++,
                $trigger = $component.find('button').attr({
                    'aria-control': listId,
                    'aria-expanded': false
                }),
                $select = $component.find('select'),
                select = $select.get(0),
                reactContext = select.hasOwnProperty('_wrapperState'),
                $value = $component.find('.js-form-select-value'),
                isMultiple = $select.attr('multiple'),
                toggleType = isMultiple ? 'checkbox' : 'radio',
                $filter,
                filterMin = $component.data('filter-min') || false,
                filterLabel = $component.data('filter-label'),
                $selectContainer = $component.find('.js-form-select-queo-container'),
                selectContainer = $selectContainer.get(0),
                $selectList = createSelectList(),
                selectList = $selectList.find('>.js-form-select-queo-list').get(0),
                $toggles = $component.find('input[type="' + toggleType + '"]'),
                outlineOffset = getOutlineWidth($trigger.get(0)),
                _isReactEvent = false,
                observeSelect = new MutationObserver(function(mutations) {

                    mutations.forEach(function(mutation) {

                        if (mutation.type === "attributes") {

                            $trigger.attr('disabled', mutation.target.disabled);

                        }

                    });

                    updateDropdownLabel();

                }),
                observeOption = new MutationObserver(function() {

                    var $option;

                    $select.find('option').each(function() {

                        $option = $(this);

                        $selectList.find('input[value="'+ $option.attr('value') + '"]').closest('.js-form-select-queo-option').toggleClass('is-hidden', $option.hasClass('is-hidden'));

                    });

                    updateDropdownLabel();

                });

            // note: must be called before any 'change' handler is bound, otherwise possible recursion may occur (react context)
            updateDropdownLabel();

            // watch for "disabled" attribute change on select element
            observeSelect.observe($select.get(0), {
                attributes: true,
                attributeFilter: ['disabled']
            });

            // watch for "is-hidden" via "class" attribute change on option element
            observeOption.observe($select.get(0), {
                attributes: true,
                attributeFilter: ['class'],
                subtree: true
            });

            // update change event (when triggered outside of component) of select to update label and triggers
            $select.on('change', function(e) {

                // ignore if it is an event meant for react
                if(_isReactEvent) {

                    e.preventDefault();

                    _isReactEvent = false;

                    return;

                }

                var values = $select.val(),
                    value,
                    $toggle,
                    isChecked;

                $toggles.each(function() {

                    $toggle = $(this);
                    value = $toggle.val();

                    isChecked = values && values.length ? (isMultiple ? values.indexOf(value) !== -1 : value === values) : false;

                    $toggle.prop('checked', isChecked);

                });

                updateDropdownLabel();

            });

            $toggles.on('change', updateDropdownLabel);
            if(!isMultiple) {

                $toggles.on('click', function() {

                    $trigger.trigger('click');

                });

            }

            // auto-submit (note: this event must be registered AFTER initial call to updateDropdownLabel)
            $select.filter('.js-auto-submit').on('change', function() {

                $(this).closest('form').submit();

            });

            // either use data-placeholder (via css :empty) or concatenate values (also silently update selected state of options)
            function updateDropdownLabel() {

                var value = [],
                    valueText = [],
                    _valueText,
                    currentValue = $select.val(),
                    $toggle,
                    isChecked,
                    nativeSelectValueSetter,
                    reactEvent;

                $toggles.each(function() {

                    $toggle = $(this);
                    isChecked = $toggle.is(':checked');

                    if(isChecked) {

                        value.push($toggle.val());

                        // concatenate values for dropdown label
                        _valueText = $toggle.next('label').text().trim();
                        if(_valueText.length) {

                            valueText.push(_valueText);

                        }

                    }

                });

                // update select (won't cause a native change event, but emit custom _changed event for other components)
                $select.val(value).trigger('_changed');

                // update button text
                $value.text(valueText.length ? valueText.join(', ') : '');

                // emit change event to react, see: https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
                if(reactContext && currentValue !== value) {

                    _isReactEvent = true;

                    nativeSelectValueSetter = Object.getOwnPropertyDescriptor(
                        window.HTMLSelectElement.prototype,
                        "value"
                    ).set;
                    nativeSelectValueSetter.call(select, value);

                    reactEvent = new Event("change", {bubbles: true});
                    select.dispatchEvent(reactEvent);

                }

            }

            $trigger.on('click', function(e) {

                if($trigger.is(':disabled')) {

                    e.preventDefault();
                    return;

                }

                bindHandler($trigger.toggleClass('is-open').is('.is-open'));

            });

            // bind or unbind handlers (also calculate alignment and init vars on bind)
            function bindHandler(bind) {

                $trigger.attr('aria-expanded', bind);

                if(bind) {

                    // bind events when open
                    $d.on('click', outsideHandler).on('keydown', keyHandler);
                    $w.on('scroll resize', alignment);
                    $selectList.on('wheel', scrollHandler).find('>.js-form-select-queo-list').scrollTop(0);

                    // empty user filter input on open
                    if($filter) {
                        $filter.val('').trigger('input');
                    }

                    // also calculate alignment
                    alignment();

                    fauxName(true);

                } else {

                    // unbind events when close
                    $d.off('click', outsideHandler).off('keydown', keyHandler);
                    $w.off('scroll resize', alignment);
                    $selectList.off('wheel', scrollHandler);

                    fauxName(false);

                }

            }

            // close on outside click/focus
            function outsideHandler(e) {

                if(e.target === selectContainer || $(e.target).closest('.js-form-select-queo-container').get(0) === selectContainer) {

                    return;

                }

                $trigger.removeClass('is-open');

                bindHandler(false);

            }

            // keyboard handler (TAB, ENTER, SPACE, ESC, KEY DOWN/UP)
            function keyHandler(e) {

                var key = e.originalEvent.key;

                if(key === 'Escape') {

                    // trigger click to close select and set focus to trigger
                    $trigger.trigger('click').trigger('focus');

                    return;

                }

                if(key === 'Tab') {

                    // wait a frame to check updated document.activeElement
                    waitFrame(function() {

                        // close opened select if document.activeElement (=element currently having focus) is either
                        // now trigger or not part of component
                        if($trigger.is(d.activeElement) || $component.find(d.activeElement).length === 0) {

                            // just trigger click to close select, currently focused element should keep it
                            $trigger.trigger('click');

                        }

                    });

                    return;

                }

                var $visibleToggles = $toggles.filter(getVisibleToggles),
                    $currentToggle = $visibleToggles.filter(':focus');

                if(key === 'ArrowDown' || key === 'ArrowUp') {

                    e.preventDefault();

                    if(key === 'ArrowDown') {

                        if($currentToggle.parent().nextAll(':visible').length) {
                            $visibleToggles.eq($currentToggle.parent().prevAll(':visible').length + 1).focus();
                        } else {
                            $visibleToggles.eq(0).focus();
                        }

                    } else {

                        if($currentToggle.parent().prevAll(':visible').length) {
                            $visibleToggles.eq($currentToggle.parent().prevAll(':visible').length - 1).focus();
                        } else {
                            $visibleToggles.eq(-1).focus();
                        }

                    }

                }

                if(key === 'Enter' && $currentToggle.length !== 0) {

                    e.preventDefault();

                    if(isMultiple) {

                        $currentToggle.prop('checked', !$currentToggle.prop('checked'));
                        updateDropdownLabel();

                    } else {

                        $currentToggle.prop('checked', true);
                        updateDropdownLabel();

                        $trigger.trigger('click').trigger('focus');

                    }

                }

            }

            function getVisibleToggles() {
                return $(this).is(':visible');
            }

            // wrapper for requestAnimationFrame (rAF must be called twice to wait for one frame - hence the wrapper)
            function waitFrame(cbk) {
                requestAnimationFrame(function() {
                    requestAnimationFrame(cbk);
                });
            }

            // prevents outside scrolling while scrolling inside box
            function scrollHandler(e) {

                QUEO.Utils.preventScrolling(e.originalEvent, selectList);

            }

            // calculate alignment (above or below)
            function alignment() {

                var vpTop = -$w.scrollTop() + $trigger.offset().top,
                    $scrollList = $(selectList).css('max-height', ''),
                    scrollListHeight = $scrollList.outerHeight(true),
                    listHeight = $selectList.outerHeight(true),
                    spaceLeftAbove = vpTop - listHeight,
                    spaceLeftBelow = (w.innerHeight - vpTop) - ($trigger.outerHeight(true) + listHeight),
                    alignment = spaceLeftBelow < 0 && spaceLeftAbove > spaceLeftBelow,
                    maxHeight;

                // toggle alignment class for styling
                $selectContainer.toggleClass('is-drop-up', alignment);

                // define max height based on alignment
                if(alignment) {

                    maxHeight = scrollListHeight + -$w.scrollTop() + $scrollList.offset().top;

                } else {

                    maxHeight = scrollListHeight + (w.innerHeight - (-$w.scrollTop() + $scrollList.offset().top) - scrollListHeight);

                }

                // apply offset from outline (top and bottom, hence "* 2")
                maxHeight -= outlineOffset * 2;

                // apply max height if there is too little space
                if(maxHeight < scrollListHeight) {

                    $scrollList.css('max-height', maxHeight + 'px');

                }

            }

            // toggle faux name on checkbox/radio (although it is only really needed for radio)
            function fauxName(isOpen) {

                $toggles.attr('name', isOpen ? 'fauxName' : null);

            }

            // used as offset for calculating a max height if there is too little space, see alignment function
            function getOutlineWidth(el) {

                return parseInt(QUEO.Utils.computedStyle(el, 'outline-width')) + parseInt(QUEO.Utils.computedStyle(el, 'outline-offset'));

            }

            function createSelectList() {

                var $selectList = $('<ul class="js-form-select-queo-list" tabindex="-1"/>').attr('id', listId),
                    $options = $select.find('option'),
                    index,
                    items = 0,
                    $option;

                // (single select only!) set first option to selected if none was selected (but only if placeholder is not present)
                if(!isMultiple && $options.filter('[selected]').length === 0 && !$value.data('placeholder')) {

                    $options.eq(0).attr('selected', true);

                }

                $options.each(function(j) {

                    index = 'QUEO_' + i + '_' + j + '_' + Date.now();

                    $option = $(this);

                    items++;

                    $selectList.append(
                        $('<li class="js-form-select-queo-option"/>').append([
                            $('<input/>').attr({
                                id: index,
                                value: $option.attr('value'),
                                disabled: $option.attr('disabled'),
                                class: 'js-form-select-queo-input',
                                checked: $option.attr('selected'),
                                type: toggleType,
                                ariaDescribedby: $select.attr('aria-describedby')
                            }),
                            $('<label/>').attr({
                                for: index
                            }).append($('<span class="focus-item"/>').text($option.text()))
                        ])
                    );

                });

                $selectList = $('<div class="ph-formfield--select-queo-container"/>').wrapInner($selectList);

                if(filterMin && items >= filterMin) {

                    $filter = $('<input type="text" value=""/>').on('input', function() {

                        // cleanup value: trim + lower cased + regex-escape
                        var value = this.value.trim().toLowerCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
                            cond,
                            isAnyVisible = false,
                            regex;

                        regex = new RegExp(value, 'gi');

                        $selectList.find('li').each(function() {

                            cond = value.length === 0 || $(this).text().trim().toLowerCase().match(regex);

                            $(this).css({
                                display: cond ? '' : 'none'
                            });

                            if(cond && !isAnyVisible) {
                                isAnyVisible = true;
                            }

                        });

                        $selectList.toggleClass('is-empty-list', !isAnyVisible);

                    }).on('focus blur', function(e){

                        $(this).parent().toggleClass('has-focus', e.type === 'focus');

                    }).attr('placeholder', filterLabel);

                    $selectList.prepend($('<div class="ph-formfield--select-queo-filter"/>').wrapInner($filter));

                }

                $trigger.after($selectList);

                return $selectList;

            }

        });

    }

    $(init);

    return {
        init: init
    }
})(window.jQuery, window, document);