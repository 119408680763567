(function($) {

    function _init() {

        $('[data-clipboard-url]:not(.js-initialized)').addClass('js-initialized').each(function (){

            var $button = $(this);

            $button.on('click', function (){
                navigator.clipboard.writeText(window.location.href)

                if(!$button.hasClass('js-copied-to-clipboard')) {

                    $button.addClass('js-copied-to-clipboard')

                    setTimeout(function (){
                        $button.removeClass('js-copied-to-clipboard')
                    }, 2500)

                }

            })

        });

    }

    $(_init);

})(window.jQuery);
