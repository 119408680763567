(function($) {

    function _init() {

        $('.js-slider-hero:not(.js-initialized)').addClass('js-initialized').each(function (){

            var $slider = $(this),
                autoplayCfg = {},
                delay = $slider.data('autoplay-delay');

            // if data-autoplay-delay is set init with autoplay params, see: https://swiperjs.com/swiper-api#autoplay
            if(delay !== '' && parseInt(delay) > 1000) {

                autoplayCfg = {
                    autoplay: {
                        delay: parseInt(delay)
                    }
                };

            }

            // only initialize swiper if there is more than one slide
            if($slider.find('.js-swiper-slide').length > 1) {

                // init current slider
                var swiper = new Swiper($slider, Object.assign({
                    a11y: {
                        paginationBulletMessage: 'Weiter zu {{index}}'
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button--next',
                        prevEl: '.swiper-button--prev',
                    },
                    on: {
                        init: function() {
                            $(this.el).trigger('init', this);
                        },
                        resize: function() {
                            $(this.el).trigger('resize', this);
                        }
                    },

                    longSwipesRatio: 0.25, // the default 0.5 is a pain to swipe
                    loop: true,
                    slidesPerView: 1,
                    watchOverflow: true
                }, autoplayCfg));

                // start autoplay if set
                if(autoplayCfg.hasOwnProperty('autoplay')) {
                    swiper.autoplay.start();
                }

            } else {

                // set a class to make single slide visible without swiper functionality / navigational elements
                $slider.addClass('no-swiper');

            }

        });

    }

    $(_init);

})(window.jQuery);