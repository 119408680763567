var QUEO = window.QUEO ||{};

QUEO.mGallery = (function ($) {

    function init() {

        $('.js-gallery:not(.js-initialized)').addClass('js-initialized').each(function(){

            $(this).find('.js-gallery-item').fancybox(getFancyboxConfig.call(this));

        });

    }

    function getFancyboxConfig() {

        var _$this = $(this),
            galleryButtons = _$this.data('gallery-buttons'),
            thumbsAutoStart = _$this.hasClass('js-thumbs-autostart');

        return {
            thumbs : {
                autoStart : thumbsAutoStart,
            },
            baseClass: thumbsAutoStart ? 'fancybox-container--show-thumbs' : '',
            toolbar: true,
            smallBtn : false,
            lang: "de",
            loop: true,
            keyboard: true,
            buttons: galleryButtons, //available buttons that can be specified in the data-gallery-buttons attribute of the module are: ["zoom","share","slideShow","fullScreen","download","thumbs","close"] //removed share since item could contain markup
            idleTime: 100,
            arrows: true,
            animationEffect: false,
            slideShow: {
                autoStart: false,
                speed: 3000
            },
            baseTpl:
                '<div class="fancybox-container" role="dialog" tabindex="-1">' +
                '<div class="fancybox-bg"></div>' +
                '<div class="fancybox-inner">' +
                '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
                '<div class="fancybox-toolbar">{{buttons}}</div>' +
                '<div class="fancybox-navigation">{{arrows}}</div>' +
                '<div class="fancybox-stage"></div>' +
                '<div class="fancybox-caption"><div class=""fancybox-caption__body"></div></div>' +
                '</div>' +
                '</div>',
            btnTpl: QUEO.Config.fancybox.btnTpl,
            share: {
                url: function(instance, item) {
                    return (
                        (!instance.currentHash && !(item.type === "inline" || item.type === "html") ? item.origSrc || item.src : false) || window.location
                    );
                },
                tpl:
                    '<div class="fancybox-share">' +
                    '<h3 class="fancybox-share__headline">{{SHARE}}</h3>' +
                    '<div class="colrow">' +
                    '<a class="fancybox-share__button fancybox-share__button--fb col col-33" href="https://www.facebook.com/sharer/sharer.php?u={{url}}">' +
                    '<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m287 456v-299c0-21 6-35 35-35h38v-63c-7-1-29-3-55-3-54 0-91 33-91 94v306m143-254h-205v72h196" /></svg>' +
                    "<span>Facebook</span>" +
                    "</a>" +
                    '<a class="fancybox-share__button fancybox-share__button--tw col col-33" href="https://twitter.com/intent/tweet?url={{url}}&text={{descr}}">' +
                    '<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m456 133c-14 7-31 11-47 13 17-10 30-27 37-46-15 10-34 16-52 20-61-62-157-7-141 75-68-3-129-35-169-85-22 37-11 86 26 109-13 0-26-4-37-9 0 39 28 72 65 80-12 3-25 4-37 2 10 33 41 57 77 57-42 30-77 38-122 34 170 111 378-32 359-208 16-11 30-25 41-42z" /></svg>' +
                    "<span>X</span>" +
                    "</a>" +
                    '<a class="fancybox-share__button fancybox-share__button--pt col col-33" href="https://www.pinterest.com/pin/create/button/?url={{url}}&description={{descr}}&media={{media}}">' +
                    '<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m265 56c-109 0-164 78-164 144 0 39 15 74 47 87 5 2 10 0 12-5l4-19c2-6 1-8-3-13-9-11-15-25-15-45 0-58 43-110 113-110 62 0 96 38 96 88 0 67-30 122-73 122-24 0-42-19-36-44 6-29 20-60 20-81 0-19-10-35-31-35-25 0-44 26-44 60 0 21 7 36 7 36l-30 125c-8 37-1 83 0 87 0 3 4 4 5 2 2-3 32-39 42-75l16-64c8 16 31 29 56 29 74 0 124-67 124-157 0-69-58-132-146-132z" fill="#fff"/></svg>' +
                    "<span>Pinterest</span>" +
                    "</a>" +
                    "</div>" +
                    '<input class="fancybox-share__input" type="text" value="{{url_raw}}" />' +
                    "</div>"
            },
            i18n: {
                de: {
                    ZOOM: 'Vergrößern / Verkleinern'
                }
            },
            video: {
                autoStart: false
            },
            autoFocus: false,
            backFocus: false,
            onActivate: triggerResize,
            beforeShow: function(instance, current) {

                if($(current.$content).find('.js-video').length !== 0) {

                    triggerResize();

                }

            },
            afterShow: function(instance, current) {

                if($(current.$content).find('.js-video').length !== 0) {

                    triggerResize();

                }

            }
        };

    }

    // actually only necessary if current item contains a video (due to size recalculation via js)
    function triggerResize() {

        requestAnimationFrame(function() {

            requestAnimationFrame(function() {

                window.dispatchEvent(new Event('resize'));

            });

        });

    }

    $(init);

    return {
        init: init,
        getFancyboxConfig: getFancyboxConfig
    };

})(window.jQuery);