/*global jQuery:false */
var QUEO = window.QUEO ||{};

QUEO.Config = $.extend(true, {
	// Globaler Debug-Parameter, welcher in QUEO.Utils.js genutzt wird
	debug: true,

    breakpointLaptop:1165,
    breakpointTablet: 919,
    breakpointMobile: 599,

    transitionDurationShort: 300,
    transitionDurationMedium: 500,
    transitionDurationLong: 800,

    transitionDurationModal: 500,

    fancybox: {
        btnTpl: {
            download:
                '<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}" href="javascript:;">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <polyline  class="stroke-color-main" points="35 74.243 100 143.756 165 74.243"/>\n' +
                '  <polyline  class="stroke-color-main" points="186 135.606 186 186.333 14 186.333 14 135.606"/>\n' +
                '  <line class="stroke-color-main" x1="99.496" y1="8.658" x2="99.496" y2="142.922"/>\n' +
                '</svg>' +
                "</a>",

            share:
                '<a data-fancybox-share class="fancybox-button fancybox-button--share" title="{{SHARE}}" href="javascript:;">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <line class="stroke-color-main" x1="73.164" y1="110.302" x2="126.836" y2="141.29"/>\n' +
                '  <line class="stroke-color-main" x1="126.836" y1="58.71" x2="73.164" y2="89.698"/>\n' +
                '  <circle class="stroke-color-main" cx="55.32" cy="100" r="20.607"/>\n' +
                '  <circle class="stroke-color-main" cx="144.68" cy="151.592" r="20.607"/>\n' +
                '  <circle class="stroke-color-main" cx="144.68" cy="48.408" r="20.607"/>\n' +
                '</svg>' +
                "</a>",

            zoom:
                '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <path class="stroke-color-main" d="M87.725,52.989v64.322M119.886,85.15H55.564m31.63-63.126a62.7,62.7,0,1,0,62.7,62.7A62.7,62.7,0,0,0,87.194,22.024Zm92.165,156.023-46.5-45.407"/>' +
                '</svg>' +
                "</button>",

            close:
                '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <line  class="stroke-color-main" x1="17.84" y1="16.5" x2="182.59" y2="181.25"/>\n' +
                '  <line  class="stroke-color-main" x1="17.84" y1="181.25" x2="182.59" y2="16.5"/>\n' +
                '</svg>' +
                "</button>",

            thumbs:
                '<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs" title="{{THUMBS}}">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <rect class="stroke-color-main" x="27.8" y="27.8" width="60" height="60"/>\n' +
                '  <rect class="stroke-color-main" x="112.2" y="27.8" width="60" height="60"/>\n' +
                '  <rect class="stroke-color-main" x="112.2" y="112.2" width="60" height="60"/>\n' +
                '  <rect class="stroke-color-main" x="27.8" y="112.2" width="60" height="60"/>\n' +
                '</svg>' +
                '</button>',

            arrowLeft:
                '<a data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}" href="javascript:;">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <polyline  class="stroke-color-main" points="65.244 186 134.756 100 65.244 14"/>\n' +
                '</svg>' +
                "</a>",

            arrowRight:
                '<a data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}" href="javascript:;">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <polyline  class="stroke-color-main" points="65.244 186 134.756 100 65.244 14"/>\n' +
                '</svg>' +
                "</a>",

            slideShow:
                '<button data-fancybox-play class="fancybox-button fancybox-button--play" title="{{PLAY_START}}">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <line class="stroke-color-main" x1="65" y1="24.892" x2="65" y2="172.2"/>\n' +
                '  <line class="stroke-color-main" x1="135" y1="24.892" x2="135" y2="172.2"/>\n' +
                '  <polygon class="stroke-color-main" points="162.638 100 32.548 24.892 32.548 175.108 162.638 100"/>\n' +
                '</svg>' +
                "</button>",

            fullScreen:
                '<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fullscreen" title="{{FULL_SCREEN}}">'
                + '<svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">\n' +
                '  <polyline  class="stroke-color-main" points="27.88 117.675 27.88 172.2 82.995 172.2"/>\n' +
                '  <line class="stroke-color-main" x1="104.075" y1="96.004" x2="29.477" y2="170.602"/>\n' +
                '  <polyline  class="stroke-color-main" points="172.12 82.325 172.12 27.8 117.005 27.8"/>\n' +
                '  <line class="stroke-color-main" x1="95.925" y1="103.996" x2="170.523" y2="29.398"/>\n' +
                '  <polyline  class="stroke-color-main" points="82.325 27.88 27.8 27.88 27.8 82.995"/>\n' +
                '  <line class="stroke-color-main" x1="103.996" y1="104.075" x2="29.398" y2="29.477"/>\n' +
                '  <polyline  class="stroke-color-main" points="117.675 172.12 172.2 172.12 172.2 117.005"/>\n' +
                '  <line class="stroke-color-main" x1="96.004" y1="95.925" x2="170.602" y2="170.523"/>\n' +
                '</svg>' +
                "</button>"
        }
    }

}, QUEO.Config);