/*global jQuery:false */
/*
* requires
* jquery-ui-autocomplete.min.js
*
* */
var QUEO = window.QUEO || {};

QUEO.Autocomplete = (function ($) {

    var _$w,
        _$b,
        autocompleteInput;

    function _init() {
        _$w = $(window);
        _$b = $('body');
        autocompleteInput = _$b.find('.js-autocomplete');

        if (autocompleteInput.length > 0) {
            _$b.on('tx_solr_updated', function () {

                //change markup of rendered options to take html instead of a string - so <strong> tag will work
                $.ui.autocomplete.prototype._renderItem = function (ul, item) {
                    return $("<li class='ui-menu-item-wrapper'></li>").data("item.autocomplete", item).append("<div>" + item.label + "</div>").appendTo(ul);
                };

                autocompleteInput.each(function () {
                    var $currentInput = $(this);
                    _autocompleteControllerInit($currentInput);
                });
            });
            _$b.trigger('tx_solr_updated'); //triggered by TYPO3
        }
    }

    function _autocompleteControllerInit($input) {
        var _$form = $input.closest('form'),
            _$formSubmit = _$form.find('button[type=submit]'),
            _thisDataUrl = _$form.data('autocomplete'),
            queryParameterKey = _$form.data('query-parameter-key') || 'tx_solr[queryString]',
            scrollEventSet = false,
            req = false,
            data = {};

        $input.autocomplete({
            appendTo: $input.parent(),
            minLength: 3,
            delay: 0,
            select: function (event, ui) {
                $input.val(ui.item.value);
                _$formSubmit.click();
            },
            source: function (request, response) {

                if (req) {
                    req.abort();
                    response();
                }

                data = {
                    L: _$form.find('input[name="L"]').val()
                };
                data[queryParameterKey] = request.term;

                req = $.ajax({
                    type: "POST",
                    url: _thisDataUrl,
                    data: data,
                    dataType: 'json',
                    cache: false,
                    success: function (json) { //returns json
                        var data = typeof json === 'object' && json.hasOwnProperty('suggestions') ? json.suggestions : {};
                        req = false;
                        _handleSuggestResponse(data, response, request);

                    },
                    fail: function () {
                        req = false;
                    }
                });


            },
            open: function (ul, items) {
                if (scrollEventSet === false) {
                    scrollEventSet = true;
                    _$w.one('scroll', function () {
                        $input.autocomplete('close');
                    });
                }
            },
            close: function (ul, item) {
                if (scrollEventSet === true) {
                    scrollEventSet = false;
                }
            }
        });
    }

    function _handleSuggestResponse(terms, response, request) {
        var output = [];
        $.each(terms, function (term) {

            output.push({
                label: term.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + $.ui.autocomplete.escapeRegex(request.term) + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<strong>$1</strong>'),
                value: term
            });
        });

        response(output);
    }


    $(_init);

    return {
        init: _init
    }

})(jQuery);