/*global jQuery:false */
/*requires
flatpickr.js
flatpickr_lang_de.js
*/

var QUEO = window.QUEO ||{};

QUEO.Flatpickr = (function($) {

    var _$b,
        _$datepickers;

    function _init() {
        _$b = $('body');
        _$datepickers = _$b.find('.js-flatpickr');

        if(_$datepickers.length > 0) {
            _$datepickers.each(function(){
                var currentDatepicker = $(this);

                _initializeFlatpickr(currentDatepicker);
            });
        }
    }

    /**
     * @param input
     * @private
     */
    function _initializeFlatpickr(input) {
        var id = input.attr('id'),
            minDateData = input.data('min-date'),
            maxDateData = input.data('max-date'),
            enableTimeData = input.data('enable-time'),
            enableWeekdays = input.data('enable-weekdays'),
            minDate = null,
            maxDate = null,
            enableTime = false,
            dateFormat = 'd.m.Y',
            day = [],
            _input;

        if( enableWeekdays === true ) {
            day = [
                function(date) {
                    // return true to disable
                    return (date.getDay() === 0 || date.getDay() === 6);

                }
            ]
        }

        //check if there is min-date data
        if(minDateData !== undefined && minDateData.length > 0) {
            minDate = minDateData;
        }

        //check if there is min-date data
        if(maxDateData !== undefined && maxDateData.length > 0) {
            maxDate = maxDateData;
        }

        //check if time is enabled
        if(enableTimeData === true) {
            enableTime = true;
            dateFormat = 'd.m.Y, H:i';
        }

        _input = input.flatpickr({
            locale: 'de',
            altInput: true,
            dateFormat: dateFormat,
            altFormat: dateFormat,
            time_24hr: true,
            minuteIncrement: 15,
            minDate: minDate,
            maxDate: maxDate,
            enableTime: enableTime,
            disable: day
        });

        // replace ids for accessibility (otherwise clicking on LABEL with FOR attribute does nothing since original INPUT is hidden)
        if(id) {
            input.attr('id', id + '_original');
            _input._input.id = id;
        }
    }

    $(_init);

    return {
        initializeFlatpickr: _initializeFlatpickr
    };

})(jQuery);